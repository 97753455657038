import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FONT_TYPES, FONTS } from "../constants/Fonts";
import "../style/StickerDialog.css";

const TemplateDialog = ({ isOpen, onClose, canvas, templateType, addText, setSelectedFont }) => {

    const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN;
    const IMG_SERVER_DOMAIN = process.env.REACT_APP_IMG_SERVER_DOMAIN;
    const STICKER_IMG_URI = IMG_SERVER_DOMAIN + "/static-img/sticker/";

    const [categories, setCategories] = useState([]);

    const keywordsRef = useRef([...FONT_TYPES]);
    const [fonts, setFonts] = useState([...FONTS]);
    
    const [templates, setTemplates] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [keyword, setKeyword] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [popupTitle, setPopupTitle] = useState("");

    const [activeFontTab, setActiveFontTab] = useState("KOR");
    

    const sampleTexts = {'KOR': '텍스트 입력', 'ENG': 'Text Here'};



    // 로드된 템플릿 목록을 저장. 재사용.
    const templatesRef = useRef({});

    // 다이얼로그가 열리고 닫힐 때 상태 초기화
    useEffect(() => {
        if (isOpen) {
            canvas.discardActiveObject();
            setKeyword(null);
            fetchTemplates();
        } else {
            setTemplates([]);
        }
        
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [isOpen]);


    const fetchTemplates = async (objectType) => {
        setTemplates([...fonts]);

        // setIsLoading(true);
        // try {
        //     const response = await fetch(SERVER_DOMAIN + "/api/template/list?objectType=" + objectType);
        //     const data = await response.json();
        //     if (data.resCd === "SUCCESS") {
        //         setTemplates(data.resObj.stickers);
        //         templatesRef.current[objectType] = data.resObj.stickers;
        //     }
        // } catch (error) {
        //     console.error("템플릿 목록 조회 실패:", error);
        // } finally {
        //     setIsLoading(false);
        // }
    };

    const filterTemplate = (type) => {
        setKeyword(type);
        setTemplates(fonts.filter((obj) => obj.type === type));
    };

    return (
        <div className="sticker-container">
            <AnimatePresence>
                {isOpen && (
                    <>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="sticker-overlay"
                            onClick={() => onClose()}
                        />

                        <motion.div
                            drag="y"
                            dragSnapToOrigin
                            dragConstraints={{ top: 0 }}
                            dragElastic={0.01}
                            onDragEnd={(_, info) => {
                                if (info.offset.y > 50) onClose();
                            }}
                            initial={{ y: "100%" }}
                            animate={{ y: 0 }}
                            exit={{ y: "100%" }}
                            transition={{ type: "spring", damping: 30, stiffness: 200 }}
                            className="sticker-modal"
                        >
                            <div className="drag-handle" />
                            <div className="template-modal-header">
                                <div className="toast-title">
                                    <svg fill="#000000" viewBox="-32 0 512 512" xmlns="http://www.w3.org/2000/svg" height="18px" width="18px"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M432 416h-23.41L277.88 53.69A32 32 0 0 0 247.58 32h-47.16a32 32 0 0 0-30.3 21.69L39.41 416H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-19.58l23.3-64h152.56l23.3 64H304a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM176.85 272L224 142.51 271.15 272z"></path></g></svg>
                                    <span>텍스트 스타일</span>
                                    <div style={{position: "absolute", right: 0}} onClick={onClose} >
                                        <img src="/tmp/svg/actions-close-svgrepo-com.svg" style={{height: "24px", width: "24px"}} />
                                    </div>
                                </div>

                                <div className="tag-scroll-container">
                                    <div className="tag-container">
                                        <button className="tag-button" onClick={() => filterTemplate('PENS')}>손글씨</button>
                                        <button className="tag-button" onClick={() => filterTemplate('BOLD')}>굵은체</button>
                                        <button className="tag-button" onClick={() => filterTemplate('CUTE')}>귀여운</button>
                                        <button className="tag-button" onClick={() => filterTemplate('SPEC')}>독특한</button>
                                        <button className="tag-button" onClick={() => filterTemplate('PENS')}>영문</button>
                                    </div>
                                </div>
                            </div>
                            <div className="template-modal-content">
                            {!keyword && (
                                <>
                                <div className="template-grid-title">
                                    <img
                                    src="/tmp/svg/famous-favorite-featured-svgrepo-com.svg"
                                    style={{ height: "20px", width: "20px" }}
                                    />
                                    <span
                                    style={{
                                        marginLeft: "5px",
                                        fontFamily: "KNPSBandali-Regular00",
                                        fontSize: "1.1rem",
                                    }}
                                    >
                                    추천 - 다채로운 폰트, 나만의 스타일
                                    </span>
                                </div>
                                <div className="template-grid">
                                    {templates
                                    .filter((obj) => obj.recommend !== null)
                                    .sort((a, b) => (a.recommend || 0) - (b.recommend || 0))
                                    .map((font) => (
                                        <div
                                        key={font.name}
                                        onClick={(e) => {
                                            onClose();
                                            addText(sampleTexts[font.language], font.fontFamily);
                                        }}
                                        className="template-item"
                                        >
                                        <img src={`${font.sampleImage}_1.webp`} className="template-grid-img" />
                                        <img src={`${font.sampleImage}_2.webp`} className="template-grid-img" />
                                        <div className="template-item-font-name">{font.name}</div>
                                        </div>
                                    ))}
                                </div>
                                </>
                            )}

                            {keywordsRef.current.map((keyword) => {
                                const filteredTemplates = templates.filter(
                                (obj) => obj.type === keyword.type
                                );

                                if (filteredTemplates.length === 0) {
                                return null;
                                }

                                return (
                                <React.Fragment key={keyword.type}> {/* React.Fragment에 key를 추가 */}
                                    <div className="template-grid-title">
                                    <img
                                        src={keyword.icon}
                                        style={{ height: "20px", width: "20px" }}
                                    />
                                    <span
                                        style={{
                                        marginLeft: "5px",
                                        fontFamily: "KNPSBandali-Regular00",
                                        fontSize: "1.1rem",
                                        }}
                                    >
                                        {keyword.typeName} - {keyword.comment}
                                    </span>
                                    </div>
                                    <div className="template-grid">
                                    {templates
                                        .filter((obj) => obj.type === keyword.type)
                                        .map((font) => (
                                        <div
                                            key={font.name}
                                            onClick={() => {
                                            onClose();
                                            addText(sampleTexts[font.language], font.fontFamily);
                                            }}
                                            className="template-item"
                                        >
                                            <img src={`${font.sampleImage}_1.webp`} className="template-grid-img" />
                                            <img src={`${font.sampleImage}_2.webp`} className="template-grid-img" />
                                            <div className="template-item-font-name">{font.name}</div>
                                        </div>
                                        ))}
                                    </div>
                                </React.Fragment>
                                );
                            })}
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </div>
    );

};

export default TemplateDialog;
