import { useEffect } from "react";
import { fabric } from "fabric";
import Hammer from "hammerjs";


export function usePinchEvents(fabricRef, orderControlsRef) {
    useEffect(() => {
        console.log(`해머 초기화`);

        const canvas = fabricRef.current;

        const hammer = new Hammer(canvas.upperCanvasEl);

        // 핀치 줌 활성화
        hammer.get("pinch").set({ enable: true });
        hammer.get("pan").set({ enable: false });
        hammer.get("rotate").set({ enable: false });
        hammer.get("swipe").set({ enable: false });
        hammer.get("tap").set({ enable: false });
        hammer.get("press").set({ enable: false });

        let lastScale = 1;
        let isPinching = false; // pinch 상태를 추적하는 플래그

        const isObjectLocked = (obj) => {
            return obj.lockScalingX || obj.lockScalingY;
        };

        // fabricjs 이벤트 핸들러 - 객체 이동 제어
        // canvas.on("object:moving", (e) => {
        //     if (isPinching || (e.target && isObjectLocked(e.target))) {
        //         // pinch 중일 때는 이동 취소
        //         e.cancel = true;
        //     }
        // });

        hammer.on("pinchstart", (e) => {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                console.log(`isObjectLocked(activeObject)=${isObjectLocked(activeObject)}`);
            }

            if (!isPinching && activeObject && !isObjectLocked(activeObject)) {

                isPinching = true; // pinch 시작

                // 현재 스케일 저장
                lastScale = activeObject.scaleX || 1;


                // 텍스트 객체인 경우 선택/편집 불가능하게 설정
                // if (activeObject.type === 'text' || activeObject.type === 'i-text') {
                //     activeObject.selectable = false;
                //     activeObject.editable = false;
                // }


                // 객체의 중심점을 기준으로 설정
                activeObject.setPositionByOrigin(
                    new fabric.Point(activeObject.getCenterPoint().x, activeObject.getCenterPoint().y),
                    "center",
                    "center"
                );

                // pinch 중에는 객체를 이동 불가능하게 설정
                activeObject.lockMovementX = true;
                activeObject.lockMovementY = true;
            }
        });

        let isCenterRunning = false;

        hammer.on("pinchmove", (e) => {
            const activeObject = canvas.getActiveObject();
            if (activeObject && !isObjectLocked(activeObject)) {
                // 새로운 스케일 계산
                const newScale = Math.min(Math.max(lastScale * e.scale, 0.1), 20);

                // 현재 중심점 위치 유지하면서 스케일만 변경
                const center = activeObject.getCenterPoint();
                activeObject.scale(newScale);

                // 중심점 위치 고정
                if (!isCenterRunning) {
                    activeObject.setPositionByOrigin(new fabric.Point(center.x, center.y), "center", "center");
                    isCenterRunning = true;
                }

                canvas.requestRenderAll();
            }
        });

        hammer.on("pinchend", (e) => {
            const activeObject = canvas.getActiveObject();
            if (isPinching && activeObject && !isObjectLocked(activeObject)) {

                setTimeout(function () {
                    // console.log(`pinchend ---------------------- 111 -- activeObject.lockMovementX`, activeObject.lockMovementX);
                    // 텍스트 객체인 경우 편집 모드 진입 방지
                    // if (activeObject.type === 'text' || activeObject.type === 'i-text') {
                    //     activeObject.selectable = true;
                    //     activeObject.editable = true;
                    // }

                    // pinch 종료 시 이동 잠금 해제
                    // activeObject.lockMovementX = false;
                    // activeObject.lockMovementY = false;
                    // activeObject.set({
                    //     hasControls: true,
                    //     lockMovementX: false,
                    //     lockMovementY: false,
                    //     lockRotationX: false,
                    //     lockRotationY: false,
                    //     lockScalingX: false,
                    //     lockScalingY: false,
                    //     // editable: true,
                    // });

                    isPinching = false; // pinch 종료
                    isCenterRunning = false;
                    
                    // historyControlsRef.current.handleCanvasChange();
                    orderControlsRef.current.unlockObject();
                }, 200);
            }
            lastScale = 1;
        });

        console.log(`해머 초기화 ==> 완료`);
    }, [fabricRef]);
}
