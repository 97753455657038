import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { COLOR_PALETTE } from '../constants/Constants';
import { FONT_TYPES, FONTS } from "../constants/Fonts";
import "../style/TextDialog.css";

const TextDialog = forwardRef(({ isOpen, onClose, selectedObject, canvas, zoomContainer, handleDoubleTap, topMenuDivRef, orderControlsDivRef, historyControlsRef }, ref) => {

    console.log(`********************************************* TextDialog 렌더링`);

    
    // 외부에서 접근할 수 있는 메서드들을 정의
    useImperativeHandle(ref, () => ({
        fontDownload: async (font) => {
            return await fontDownload(font);
        },
        setSelectedFont: (fontFamily) => {
            return setSelectedFont(fontFamily);
        },
        // isActiveTab: () => {
        //     return activeTab ? true : false;
        // },
    }));

    
    const [activeTab, setActiveTab] = useState("TEXT");
    const [activeFontDetailTab, setActiveFontDetailTab] = useState(null);

    const [activeFont, setActiveFont] = useState(null);


    const [templates, setTemplates] = useState([]);



    // 객체를 선택해서 컨트롤을 업데이트 할경우 히스토리 증가 문제를 방지하기 위함.
    const isFirstSelectObjectRef = useRef(false);

    // 텍스트 스타일 관련 상태
    // const [text, setText] = useState('');
    const [textStyle, setTextStyle] = useState({
        fontSize: 80,
        lineHeight: 1.0,
        tempLineHeight: 1.0,
        charSpacing: 0,
        tempCharSpacing: 0,
        isBold: false,
        isItalic: false,
        underline: false,
        textAlign: 'center',
    });

    const originalTextRef = useRef('');
    const [caseState, setCaseState] = useState(0);

    const handleToggleCase = () => {
        if (!selectedObject) return;

        if (caseState === 0) {
            originalTextRef.current = selectedObject.text;
        }

        const nextState = (caseState + 1) % 3;
        setCaseState(nextState);

        let newText = "";
        switch (nextState) {
            case 0: // 원본
                newText = originalTextRef.current;
                break;
            case 1: // 대문자
                newText = selectedObject.text.toUpperCase();
                break;
            case 2: // 소문자
                newText = selectedObject.text.toLowerCase();
                break;
        }

        // 텍스트 업데이트
        selectedObject.set("text", newText);
        canvas.renderAll();
    };

    // 폰트 관련 상태
    const [selectedFont, setSelectedFont] = useState('');
    const [isFontLoading, setIsFontLoading] = useState(false);
    const keywordsRef = useRef([...FONT_TYPES]);
    const [fonts, setFonts] = useState([...FONTS]);

    const [objectColor, setObjectColor] = useState('');
    const [selectedColor, setSelectedColor] = useState('');

    
    // 
    useEffect(() => {
        console.log(`TextDialog::activeTab`);

        if (activeTab) {
            topMenuDivRef.current.classList.remove("show");
            topMenuDivRef.current.classList.add("hide");
            orderControlsDivRef.current.classList.remove("show");
            orderControlsDivRef.current.classList.add("hide");
            zoomContainer(true, window.innerHeight - (300));
        } else {
            topMenuDivRef.current.classList.remove("hide");
            topMenuDivRef.current.classList.add("show");
            // orderControlsDivRef.current.classList.remove("hide");
            // orderControlsDivRef.current.classList.add("show");
            zoomContainer(false);
        }
               
        return () => {};
    }, [activeTab]);

    
    // 팔레트 색상이 변경 -> 객체 색상 업데이트
    useEffect(() => {
        console.log(`ColorControls::useEffect(1) - 객체 색상 업데이트`);

        if (!objectColor) return;

        if (selectedObject) {
            if (selectedObject.type === 'text' || selectedObject.type === 'i-text' || selectedObject.type === 'rect') {
                selectedObject.set({
                    fill: objectColor.color
                });
                canvas.renderAll();
                setSelectedColor(objectColor.color);
                historyControlsRef.current.handleCanvasChange(); // 히스토리에 추가
            } else {
                setSelectedColor(null);
            }
        } else {
            setSelectedColor(null);
        }
               
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [objectColor]);

    // 선택된 객체가 변경 -> 팔레트 색상 업데이트
    useEffect(() => {
        console.log(`ColorControls::useEffect(2) - 팔레트 색상 업데이트`);
        setActiveTab(null);
        setCaseState(0);
        if (selectedObject && (selectedObject.type === 'i-text' || selectedObject.type === 'rect')) {
            setSelectedColor(selectedObject.fill || '#000000');
            setObjectColor(COLOR_PALETTE.find((obj) => obj.color === selectedObject.fill));
            // console.log(`selectedObject.fontFamily=`, selectedObject.fontFamily);
            // const font = fonts.find(f => f.fontFamily === selectedObject.fontFamily);
            // console.log(`font=`, font);
            // if (font) {
            //     setFontPreviewImg(font.sampleImage);
            // }
        } else {
            setSelectedColor('');
            setObjectColor('');
        }
    }, [selectedObject]);
    
    // 컨트롤 변경 -> 텍스트 적용
    useEffect(() => {
        console.log(`TextControls::useEffect(텍스트 스타일 변경 시 적용)`);
        if (selectedObject && (selectedObject.type === "text" || selectedObject.type === "i-text")) {
            selectedObject.set({
                fontSize: textStyle.fontSize,
                lineHeight: textStyle.lineHeight,
                charSpacing: textStyle.charSpacing,
                fontWeight: textStyle.isBold ? 'bold' : 'normal',
                fontStyle: textStyle.isItalic ? 'italic' : 'normal',
                underline: textStyle.underline,
            });
            canvas.renderAll();

            console.log(`isFirstSelectObjectRef.current=${isFirstSelectObjectRef.current}`);
            // if (!isFirstSelectObjectRef.current) {
                historyControlsRef.current.handleCanvasChange();
            // }
            // isFirstSelectObjectRef.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textStyle]);

    
    // 폰트 다운로드 및 적용
    useEffect(() => {
        console.log(`TextControls::useEffect(폰트 다운로드 및 적용)`);

        if (!selectedFont) return;

        const font = fonts.find(f => f.fontFamily === selectedFont);
        if (!font || font.isLoaded) return;

        fontDownload(selectedFont);

        return () => {
            const style = document.querySelector(`style[data-font="${font.fontFamily}"]`);
            if (style) {
                document.head.removeChild(style);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFont]);


    // 폰트 변경 -> 컨트롤 적용
    useEffect(() => {
        console.log(`TextControls::useEffect(폰트 변경 시 적용)`);

        const font = fonts.find(f => f.fontFamily === selectedFont);
        if (!font) return;

        if (!isFontLoading && font.isLoaded && selectedObject &&  (selectedObject.type === "text" || selectedObject.type === "i-text")) {
            selectedObject.set({
                fontFamily: selectedFont,
            });
            canvas.renderAll();
            historyControlsRef.current.handleCanvasChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFontLoading, selectedFont]);


    const changeActiveTab = (tabName) => {
        setActiveTab(tabName);
        setActiveFontDetailTab(false);
        setTemplates([...fonts]);
        console.log(`selectedObject.fontFamily=`, selectedObject.fontFamily);
        const font = fonts.find(f => f.fontFamily === selectedObject.fontFamily);
        console.log(`font=`, font);
        if (font) {
            setActiveFont(font);
        }
    };

    const handleBack = () => {
        setActiveFontDetailTab(false);
    };


    const fontDownload = async (inputFont) => {
        const font = fonts.find(f => f.fontFamily === inputFont);
        if (!font || font.isLoaded) return;
    
        setIsFontLoading(true);
        
        try {
            const style = document.createElement('style');
            style.setAttribute('data-font', font.fontFamily);
            style.textContent = `
                @font-face {
                    font-family: '${font.fontFamily}';
                    src: url('${font.path}') format('woff2');
                    font-weight: normal;
                    font-style: normal;
                }
            `;
            await new Promise(resolve => {
                document.head.appendChild(style);
                // style 태그가 완전히 추가되었는지 확인
                setTimeout(resolve, 100);
            });

            // 2. FontFace 인스턴스 생성 및 로딩
            const fontFace = new FontFace(font.fontFamily, `url(${font.path})`);
            const loadedFont = await fontFace.load();

            // 3. 폰트 추가
            await new Promise(resolve => {
                document.fonts.add(loadedFont);
                resolve();
            });

            // 4. 폰트 준비 완료 대기
            await document.fonts.ready;

            console.log(`폰트 로딩 완료: ${inputFont}`);

            setIsFontLoading(false);
            setFonts(prevFonts =>
                prevFonts.map(f =>
                    f.fontFamily === font.fontFamily
                        ? { ...f, isLoaded: true }
                        : f
                )
            );

            // historyControlsRef.current.handleCanvasChange();
        } catch (error) {
            console.error('폰트 로딩 실패:', error);
            setIsFontLoading(false);
        }
    };

    const updateTextStyle = (key, value) => {
        setTextStyle(prev => ({
            ...prev,
            [key]: value
        }));
    };


    // 텍스트 정렬
    const setTextAlign = (alignment) => {
        if (selectedObject && (selectedObject.type === "text" || selectedObject.type === "i-text")) {
            selectedObject.set('textAlign', alignment);
            updateTextStyle("textAlign", alignment);
            canvas.renderAll();
            historyControlsRef.current.handleCanvasChange();
        }
    };


    return (
        <div className="text-container">
            <AnimatePresence>
                {isOpen && (
                    <>
                        <motion.div
                            // drag="y"
                            // dragSnapToOrigin
                            // dragConstraints={{ top: 0 }}
                            // dragElastic={0.2}
                            // onDragEnd={(_, info) => {
                            //     if (info.offset.y > 100) onClose();
                            // }}
                            initial={{ y: "100%" }}
                            animate={{ y: 0 }}
                            exit={{ y: "100%" }}
                            // transition={{ type: "spring", damping: 20, stiffness: 200 }}
                            className="text-modal"
                        >
                            {/* <div className="drag-handle" /> */}
                            <div className="text-modal-header">
                                <div className="text-modal-header-container">
                                    <div className={`bottom-menu-item tab-button`}>
                                        <button
                                            className="menu-icon"
                                            onClick={() => {
                                                changeActiveTab("TEXT");
                                                // zoomContainer(true, 500);
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="20px"
                                                viewBox="0 -960 960 960"
                                                width="20px"
                                                fill="#5f6368"
                                            >
                                                <path d="M248-240h104v-13h-51l53-147h134l49 147h-47v13h222v-13h-31L509-720h-55L280-253h-32v13Zm111-175 64-178 60 178H359ZM168-96q-29.7 0-50.85-21.15Q96-138.3 96-168v-624q0-29.7 21.15-50.85Q138.3-864 168-864h624q29.7 0 50.85 21.15Q864-821.7 864-792v624q0 29.7-21.15 50.85Q821.7-96 792-96H168Zm0-72h624v-624H168v624Zm0 0v-624 624Z" />
                                            </svg>
                                        </button>
                                        <div>폰트</div>
                                    </div>
                                    <div className={`bottom-menu-item tab-button`}>
                                        <button className="menu-icon" onClick={() => changeActiveTab("COLOR")}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="20px"
                                                viewBox="0 -960 960 960"
                                                width="20px"
                                                fill="#5f6368"
                                            >
                                                <path d="M480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30.5-149.5t84-122Q264-804 335.5-834T488-864q78 0 146.5 27T754-763q51 47 80.5 110T864-518q0 96-67 163t-163 67h-68q-8 0-14 5t-6 13q0 15 15 25t15 53q0 37-27 66.5T480-96Zm0-384Zm-216 36q25 0 42.5-17.5T324-504q0-25-17.5-42.5T264-564q-25 0-42.5 17.5T204-504q0 25 17.5 42.5T264-444Zm120-144q25 0 42.5-17.5T444-648q0-25-17.5-42.5T384-708q-25 0-42.5 17.5T324-648q0 25 17.5 42.5T384-588Zm192 0q25 0 42.5-17.5T636-648q0-25-17.5-42.5T576-708q-25 0-42.5 17.5T516-648q0 25 17.5 42.5T576-588Zm120 144q25 0 42.5-17.5T756-504q0-25-17.5-42.5T696-564q-25 0-42.5 17.5T636-504q0 25 17.5 42.5T696-444ZM480-168q11 0 17.5-8.5T504-192q0-16-15-28t-15-50q0-38 26.5-64t64.5-26h69q66 0 112-46t46-112q0-115-88.5-194.5T488-792q-134 0-227 91t-93 221q0 130 91 221t221 91Z" />
                                            </svg>
                                        </button>
                                        <div>색상</div>
                                    </div>

                                    <div className={`bottom-menu-item tab-button`}>
                                        <button className="menu-icon" onClick={() => handleDoubleTap(selectedObject)}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="20px"
                                                viewBox="0 -960 960 960"
                                                width="20px"
                                                fill="#5f6368"
                                            >
                                                <path d="M456-96v-240h72v84h288v72H528v84h-72Zm-312-84v-72h264v72H144Zm135-252h74.7l39.6-110H567l38.9 110H681L518-864h-76L279-432Zm137-173 66-179 63 179H416Z" />
                                            </svg>
                                        </button>
                                        <div>편집</div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>

            {activeTab && (
                <AnimatePresence>
                    <motion.div
                        drag="y"
                        dragSnapToOrigin
                        dragConstraints={{ top: 0 }}
                        dragElastic={0.01}
                        onDragEnd={(_, info) => {
                            if (info.offset.y > 50) setActiveTab(null);
                        }}
                        initial={{ y: "100%" }}
                        animate={{ y: 0 }}
                        exit={{ y: "100%" }}
                        transition={{ type: "spring", damping: 30, stiffness: 200 }}
                        className="text-font-modal"
                    >
                        <div className="drag-handle" />

                        {!activeFontDetailTab && (
                            <>
                                <div className="tab-container">
                                    <button
                                        className={`tab-button ${activeTab === "TEXT" ? "active" : ""}`}
                                        onClick={() => changeActiveTab("TEXT")}>
                                        텍스트
                                    </button>
                                    <button
                                        className={`tab-button ${activeTab === "COLOR" ? "active" : ""}`}
                                        onClick={() => changeActiveTab("COLOR")}>
                                        색상
                                    </button>
                                </div>
                                {activeTab === "TEXT" && (
                                    <div className="text-font-modal-content">
                                        <div className="selected-font" onClick={() => setActiveFontDetailTab(true)}>
                                            <div className="selected-font-img">
                                                {activeFont && (
                                                    <span style={{fontFamily: activeFont.fontFamily}}>{activeFont.name}</span>
                                                )}
                                                <div className="arrow"></div>
                                            </div>
                                        </div>
                                        <div className="text-button-group">
                                            <div className="text-style-buttons">
                                                <div className="text-style-buttons-item">
                                                    <button
                                                        className={`text-style-button ${textStyle.isBold ? "active" : ""}`}
                                                        onClick={() => updateTextStyle("isBold", !textStyle.isBold)}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#000000"><path d="M266-192v-576h227.95q67.05 0 123.55 41.32Q674-685.35 674-612q0 51-22.5 79.5T609-490.96Q635-479 665-448t30 91q0 91-67.03 128t-125.81 37H266Zm127-118h104.68Q546-310 556-334.5t10-35.5q0-11-10.5-35.5T494-430H393v120Zm0-232h93q33 0 48.5-17.5T550-597q0-24-17.11-39t-44.28-15H393v109Z"/></svg>
                                                    </button>
                                                    <div>굵게</div>
                                                </div>
                                                <div className="text-style-buttons-item">
                                                    <button
                                                        className={`text-style-button ${textStyle.isItalic ? "active" : ""}`}
                                                        onClick={() => updateTextStyle("isItalic", !textStyle.isItalic)}
                                                        ><img src="/assets/icons/italic-svgrepo-com.svg" style={{height: "16px", width: "16px"}} />
                                                    </button>
                                                    <div>기울기</div>
                                                </div>
                                                <div className="text-style-buttons-item">
                                                    <button
                                                        className={`text-style-button ${textStyle.underline ? "active" : ""}`}
                                                        onClick={() => updateTextStyle("underline", !textStyle.underline)}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="20px"
                                                            viewBox="0 -960 960 960"
                                                            width="20px"
                                                            fill="#000000"
                                                        >
                                                            <path d="M200-120v-80h560v80H200Zm280-160q-101 0-157-63t-56-167v-330h103v336q0 56 28 91t82 35q54 0 82-35t28-91v-336h103v330q0 104-56 167t-157 63Z" />
                                                        </svg>
                                                    </button>
                                                    <div>밑줄</div>
                                                </div>
                                                <div className="text-style-buttons-item">
                                                    <button
                                                        className={`text-style-button`}
                                                        onClick={handleToggleCase}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="30px"
                                                            viewBox="0 -960 960 960"
                                                            width="30px"
                                                            fill="#000000"
                                                        >
                                                            <path d="m142-269 162.3-432H382l162 432h-74.7l-38.34-110H256l-39.31 110H142Zm136-172h130l-63.06-179H341l-63 179Zm385 182q-51 0-79.5-26.5T555-359q0-44 33-70.5t87-26.5q20 0 41 4t40 11v-9q0-29.89-20.08-48.45Q715.84-517 682.53-517 660-517 642-508t-33 28l-45-35q20-27 51.5-41.5T685-571q64 0 98.5 31.5T818-451v182h-62v-36h-3q-17 24-39 35t-51 11Zm10.56-53q34.35 0 58.39-23.5Q756-359 756-391.8q-14-8.2-31.5-12.2t-37.25-4Q654-408 637-395.5t-17 36.57Q620-338 635-325t38.56 13Z" />
                                                        </svg>
                                                    </button>
                                                    <div>대소문자</div>
                                                </div>
                                            </div>
                                            <div className="text-style-buttons">
                                                <div className="text-style-buttons-item">
                                                    <button className={`text-style-button ${textStyle.textAlign === 'left' ? "active" : ""}`} onClick={() => setTextAlign("left")}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="18px"
                                                            viewBox="0 -960 960 960"
                                                            width="18px"
                                                            fill="#5f6368"
                                                        >
                                                            <path d="M120-120v-80h720v80H120Zm0-160v-80h480v80H120Zm0-160v-80h720v80H120Zm0-160v-80h480v80H120Zm0-160v-80h720v80H120Z" />
                                                        </svg>
                                                    </button>
                                                    <div>왼쪽</div>
                                                </div>
                                                <div className="text-style-buttons-item">
                                                    <button className={`text-style-button ${textStyle.textAlign === 'center' ? "active" : ""}`} onClick={() => setTextAlign("center")}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="18px"
                                                            viewBox="0 -960 960 960"
                                                            width="18px"
                                                            fill="#5f6368"
                                                        >
                                                            <path d="M120-120v-80h720v80H120Zm160-160v-80h400v80H280ZM120-440v-80h720v80H120Zm160-160v-80h400v80H280ZM120-760v-80h720v80H120Z" />
                                                        </svg>
                                                    </button>
                                                    <div>가운데</div>
                                                </div>
                                                <div className="text-style-buttons-item">
                                                    <button className={`text-style-button ${textStyle.textAlign === 'right' ? "active" : ""}`} onClick={() => setTextAlign("right")}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="18px"
                                                            viewBox="0 -960 960 960"
                                                            width="18px"
                                                            fill="#5f6368"
                                                        >
                                                            <path d="M120-760v-80h720v80H120Zm240 160v-80h480v80H360ZM120-440v-80h720v80H120Zm240 160v-80h480v80H360ZM120-120v-80h720v80H120Z" />
                                                        </svg>
                                                    </button>
                                                    <div>오른쪽</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slider-container">
                                            <div className="slider-row">
                                                <span className="slider-label">글자 크기</span>
                                                <input
                                                    type="range"
                                                    min="40"
                                                    max="180"
                                                    value={textStyle.fontSize}
                                                    onChange={(e) => updateTextStyle("fontSize", e.target.value)}
                                                    className="slider-input"
                                                />
                                                <input
                                                    type="number"
                                                    value={textStyle.fontSize}
                                                    onChange={(e) => updateTextStyle("fontSize", e.target.value)}
                                                    className="number-input"
                                                />
                                            </div>
                                            <div className="slider-row">
                                                <span className="slider-label">행 간격</span>
                                                <input
                                                    type="range"
                                                    min="0"
                                                    max="1000"
                                                    step="100"
                                                    value={textStyle.charSpacing}
                                                    onChange={(e) => updateTextStyle("charSpacing", parseInt(e.target.value))}
                                                    className="slider-input"
                                                />
                                                <input
                                                    type="number"
                                                    value={textStyle.charSpacing}
                                                    onChange={(e) => updateTextStyle("charSpacing", parseInt(e.target.value))}
                                                    className="number-input"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activeTab === "COLOR" && (
                                    <div className="text-font-modal-content">
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", justifyContent: "space-evenly", height: "35px", marginBottom: "10px" }}>
                                            <div style={{ width: "100px", textAlign: "center"}}>{objectColor ? objectColor.korName : "흰색"}</div>
                                            <div style={{ height: "5px", width: "200px", border: "1px solid #000", borderRadius: "1rem", backgroundColor: objectColor ? objectColor.color : "#FFFFFF" }}></div>
                                        </div>
                                        <div className="color-grid">
                                            {COLOR_PALETTE.map((color) => (
                                                <div key={color.color} className={`color-button-outline ${selectedColor === color.color ? "active" : ""}`}>
                                                    <div
                                                       
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // 이벤트 버블링 방지
                                                            setObjectColor(color);
                                                            // console.log(`color`, color);
                                                        }}
                                                        className={`color-button`}
                                                        style={{
                                                            backgroundColor: color.color,
                                                            border: '#FFFFFF' === color.color ? "1px solid #ccc" : "",
                                                        }}
                                                    ></div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        {activeFontDetailTab && (
                            <>
                                <div className="text-modal-header">
                                    <div style={{ display: "flex" }}>
                                        <button
                                            className="back-button"
                                            onClick={handleBack}
                                            style={{
                                                border: "none",
                                                background: "none",
                                                cursor: "pointer",
                                                fontSize: "12px",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "0",
                                                marginBottom: "10px",
                                                color: "#666",
                                            }}
                                        >
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                <path d="M19 12H5M12 19l-7-7 7-7" />
                                            </svg>
                                        </button>
                                        <span className="sticker-modal-title"
                                            style={{ fontSize: "1.1rem" }}>텍스트 스타일</span>
                                    </div>
                                </div>
                                <div className="text-modal-content">
                                    {keywordsRef.current.map((keyword) => ( 
                                        <div key={keyword.type}>
                                            <div className="font-grid-title">
                                                <img src={keyword.icon} style={{height: "20px", width: "20px"}}/>
                                                <span style={{marginLeft: '5px', fontFamily: "", fontSize: "1.0rem"}}>{keyword.typeName} - {keyword.comment}</span>
                                            </div>
                                            <div className="font-grid">
                                                {templates
                                                    .filter((obj) => obj.type === keyword.type)
                                                    .map((font) => (
                                                        <div
                                                            key={font.name}
                                                            onClick={() => {
                                                                setSelectedFont(font.fontFamily);
                                                                setActiveFont(font);
                                                            }}
                                                            className={`font-item ${font.fontFamily === activeFont.fontFamily ? "active" : ""}`}
                                                        >
                                                            <img src={`${font.sampleImage}_1.webp`} className="font-item-img" />
                                                            <img src={`${font.sampleImage}_2.webp`} className="font-item-img" />
                                                            <div className="font-name">{font.name}</div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </motion.div>
                </AnimatePresence>
            )}
        </div>
    );
});

export default TextDialog;