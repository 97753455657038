import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, useCallback } from "react";
import { fabric } from "fabric";
import { initMockup } from "./initMockup";
import { initMockupNever } from "./initMockupNever";
import { useDoubleTapEvents } from '../hooks/useDoubleTapEvents';
import { usePinchEvents } from '../hooks/usePinchEvents';
import { loadImage,  } from '../utils/fabricUtils';
import PreviewDialog from "./PreviewDialog";
import HistoryControls from "./HistoryControls";
import SizeInfoTooltip from "./SizeInfoTooltip";
import LayerPanel from "./LayerPanel";
import OrderControls from "./OrderControls";
import TextToolbar from "./TextToolbar";
import TextDialog from "./TextDialog";
import ColorControls from "./ColorControls";
import ImageManagerDialog from "./ImageManagerDialog";
import StickerDialog from "./StickerDialog";
import TemplateDialog from "./TemplateDialog";
import "../style/CanvasContainer.css";
import {
    CANVAS_DIMENSIONS,
    SNAP_THRESHOLD,
    CONTROL_VISIBILITY,
    CONTROL_VISIBILITY_TEXTBOX,
    COLOR_PALETTE,
    TEXT_DEFAULTS
} from "../constants/Constants";

const CanvasComponent = forwardRef(({marketId, userId, canvasId, designType, designCode, deviceCode, area, initSettings, saveContentsToServer}, ref) => {
    console.log(
        `***************************************** CanvasComponent 렌더링 [${userId} - ${designType} - ${deviceCode} - ${area} - ${initSettings[area]}]`
    );

    // const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN;
    const IMG_SERVER_DOMAIN = process.env.REACT_APP_IMG_SERVER_DOMAIN;

    const [isTextPopupOpen, setIsTextPopupOpen] = useState(false);
    const [isColorDialogOpen, setIsColorDialogOpen] = useState(false);
    const [isTemplatePopupOpen, setIsTemplatePopupOpen] = useState(false);
    const [isStickerPopupOpen, setIsStickerPopupOpen] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [isImageManagerOpen, setIsImageManagerOpen] = useState(false);


    // 레이아웃 관련
    const appContainerRef = useRef(null); // 
    const containerRef = useRef(null); // 캔버스를 감싸는 컨테이너
    const canvasRef = useRef(null); // 캔버스
    const topMenuDivRef = useRef(null);
    const orderControlsDivRef = useRef(null);
    const colorControlsDivRef = useRef(null);
    const textToolbarDivRef = useRef(null);
    const landscapeAlertRef = useRef(null);
    const originalSizeRef = useRef(null);
    const originalWindowHeight = useRef(window.innerHeight);

    // Canvas 관련
    const fabricRef = useRef(null); // 패브릭
    const isVShowRef = useRef(false);
    const phoneBaseImageRef = useRef(null);
    const [selectedObject, setSelectedObject] = useState(null); // 현재 선택된 객체
    const canvasScaleRef = useRef(1); // 캔버스를 화면에 맞추기 위해 줄여진 스케일
    const canvasCenterRef = useRef({});
    const textDialogRef = useRef(null);
    const orderControlsRef = useRef(null);

    // 체크 관련
    const checkAreaInsideRef = useRef(null);
    const checkAreaOutsideRef = useRef(null);
    const [alertMessage, setAlertMessage] = useState(null); // 경고 메세지 저장

    // 히스토리 관련
    const historyControlsRef = useRef(null);
    // const [hisIndex, setHisIndex] = useState(0);


    // const isTextInputFocusRef = useRef(null);



    // 외부에서 접근할 수 있는 메서드들을 정의
    useImperativeHandle(ref, () => ({
        getCanvas: () => fabricRef.current,
        updateOverlayGroup: () => updateOverlayGroup(),
        setIsPreviewOpen: () => setIsPreviewOpen(true),
    }));


    useEffect(() => {
        console.log(`Canvas::useEffect(1) -- initializeAll`);

        const initializeAll = async () => {
            await initCanvas();
            await initSnap();
            await initCustomControl();
            await initCanvasEvent();

            if (designType === "HARD") {
                await initMockup(
                    fabricRef,
                    checkAreaInsideRef,
                    checkAreaOutsideRef,
                    phoneBaseImageRef,
                    historyControlsRef,
                    deviceCode,
                    initSettings[area]
                );
            } else if (designType === "NEVER") {
                await initMockupNever(
                    fabricRef,
                    checkAreaInsideRef,
                    checkAreaOutsideRef,
                    phoneBaseImageRef,
                    historyControlsRef,
                    deviceCode,
                    initSettings[area]
                );
            }

            await adjustZoom();

            setTimeout(() => {
                originalSizeRef.current = {
                    width: fabricRef.current.getWidth(),
                    height: fabricRef.current.getHeight(),
                    zoom: fabricRef.current.getZoom(),
                    viewportTransform: fabricRef.current.viewportTransform
                };
            }, 1000);

            
        };

        initializeAll();

        return () => {
            console.log(`폐기 : fabricRef`);
            fabricRef.current.dispose();
            fabricRef.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initCanvas = () => {
        return new Promise((resolve, reject) => {
            console.log(`캔버스 초기화 [${area}]`);

            const canvas = new fabric.Canvas(canvasId, {
                name: area,
                width: CANVAS_DIMENSIONS.ORIGINAL_WIDTH,
                height: CANVAS_DIMENSIONS.ORIGINAL_HEIGHT,
                backgroundColor: "#fff",
                preserveObjectStacking: true, // 객체의 Z-축 순서를 유지하도록 설정
                targetFindTolerance: 0,
                selection: false,   // 다중 선택 방지
            });

            fabricRef.current = canvas;
            
            // 커스텀 편집 컨트롤 추가
            fabric.Object.prototype.controls.editBtn = new fabric.Control({
                x: 0,
                y: 0.5,
                offsetY: 20,
                cursorStyle: 'pointer',
                mouseUpHandler: function(eventData, transform) {
                    console.log(`커스텀 편집 컨트롤 mouseUpHandler`);
                    const target = transform.target;
                    handleDoubleTap(target);
                    return true;
                },
                visible: function(fabricObject) {
                    // i-text 객체일 경우에만 컨트롤을 표시
                    return fabricObject.type === 'i-text';
                },
                render: function(ctx, left, top, styleOverride, fabricObject) {
                    // visible이 false인 경우 렌더링하지 않음
                    if (!this.visible(fabricObject)) {
                        return;
                    }
            
                    const size = 30;
                    ctx.save();
                    ctx.translate(left, top);
                    ctx.beginPath();
                    ctx.fillStyle = '#a00';
                    ctx.arc(0, 0, size / 2, 0, 2 * Math.PI);
                    ctx.fill();
            
                    // 편집 아이콘 그리기
                    ctx.fillStyle = '#ffffff';
                    ctx.font = '14px Arial';
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fillText('✎', 0, 0);
                    ctx.restore();
                }
            });

            console.log("캔버스 초기화 ==> 완료");

            resolve();
        });
    };

    const initSnap = () => {
        return new Promise((resolve, reject) => {
            console.log("스냅 기능 초기화");

            const showVerticalGuideline = () => {
                const canvas = fabricRef.current;

                if (!isVShowRef.current) {
                    const vGuideLine = canvas.getObjects().find((obj) => obj.type === "line" && obj.name === "vGuideLine");
                    vGuideLine.set("opacity", 1);
                    isVShowRef.current = true;
                }
            };

            const hideVerticalGuideline = () => {
                const canvas = fabricRef.current;

                if (isVShowRef.current) {
                    const vGuideLine = canvas.getObjects().find((obj) => obj.type === "line" && obj.name === "vGuideLine");
                    if (vGuideLine) {
                        vGuideLine.set("opacity", 0);
                        isVShowRef.current = false;
                    }
                }
            };

            function hideGuideLine() {
                hideVerticalGuideline();
                // console.log(`가이드라인 숨기기`);
            }

            
            const snapRotating = (obj) => {

                const SNAP_ANGLE = 90;

                let currentAngle = obj.angle;
                const snapAngle = Math.round(currentAngle / SNAP_ANGLE) * SNAP_ANGLE;
                const diff = Math.abs(currentAngle - snapAngle);

                // 차이가 5도 이내일 경우 스냅
                if (diff < 5) {
                    obj.set("angle", snapAngle);
                }
            };

            const canvas = fabricRef.current;

            // 스냅 기능 구현
            canvas.on({
                "object:moving": (e) => {
                    const obj = e.target;
                    const objCenter = obj.getCenterPoint();

                    // 가로 중앙 스냅
                    if (Math.abs(objCenter.x - CANVAS_DIMENSIONS.CENTER_X) < SNAP_THRESHOLD) {
                        // console.log('가로 중앙 스냅');
                        obj.set({
                            left: CANVAS_DIMENSIONS.CENTER_X,
                        });
                        showVerticalGuideline();
                    } else {
                        hideVerticalGuideline();
                    }
                },

                // 객체 이동 종료시 가이드라인 제거
                "mouse:up": hideGuideLine,
                "touch:end": hideGuideLine,
            });

            canvas.on("object:rotating", (e) => {
                snapRotating(e.target);
            });

            

            console.log("스냅 기능 초기화 ==> 완료");

            resolve();
        });
    };

    const initCustomControl = () => {
        return new Promise((resolve, reject) => {
            console.log("커스텀 컨트롤 셋팅");

            // 모든 객체에 대한 기본 컨트롤 설정
            fabric.Object.prototype.set({
                hasControls: true,
                hasBorders: true,
                borderColor: "#FFA500",
                cornerColor: "#FFA500",
                cornerSize: 15,
                cornerStyle: "rect", // 'circle' 또는 'rect'
                transparentCorners: false,
                padding: 0,
            });

            // 이미지 객체 생성
            const rotationIcon = new Image();
            rotationIcon.src = "assets/icons/rotate-dot.svg";

            // 커스텀 회전 컨트롤 정의
            const customRotationControl = new fabric.Control({
                x: 0,
                y: -0.5,
                offsetY: -40,
                cursorStyle: "pointer",
                actionHandler: fabric.controlsUtils.rotationWithSnapping,
                actionName: "rotate",
                withConnection: true, // 연결 선 그리기
                render: function (ctx, left, top, styleOverride, fabricObject) {
                    const size = 24;

                    if (rotationIcon.complete) {
                        ctx.save();
                        ctx.translate(left, top);
                        //   ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));  // 아이콘도 같이 회전하기
                        ctx.drawImage(rotationIcon, -size / 2, -size / 2, size, size);
                        ctx.restore();
                    } else {
                        rotationIcon.onload = () => fabricObject.canvas.requestRenderAll();
                    }
                },
            });

            // 일반 객체에 적용
            fabric.Object.prototype.controls.mtr = customRotationControl;

            // 텍스트 객체에 적용
            fabric.IText.prototype.controls.mtr = customRotationControl;
            fabric.Textbox.prototype.controls.mtr = customRotationControl;

            console.log("커스텀 컨트롤 셋팅 ==> 완료");

            resolve();
        });
    };

    const initialPositionRef = useRef(null);

    const initCanvasEvent = () => {
        return new Promise((resolve, reject) => {
            console.log("캔버스 이벤트 초기화");

            const canvas = fabricRef.current;

            // 객체 선택 시 호출되는 함수
            const handleObjectSelected = (e) => {
                const activeObject = e.selected[0];
                setSelectedObject(activeObject);

                if (activeObject.type === "textbox") {
                    activeObject.setControlsVisibility(CONTROL_VISIBILITY_TEXTBOX);
                } else {
                    activeObject.setControlsVisibility(CONTROL_VISIBILITY);
                }

                // 객체가 선택되었을 때 초기 위치 저장 (터치 미끄러짐 히스토리 기록 방지용)
                console.log(`객체가 선택되었을 때 초기 위치 저장`);
                initialPositionRef.current = {
                    x: activeObject.left,
                    y: activeObject.top,
                };
            };

            // 선택 해제 시 호출되는 함수
            const handleSelectionCleared = () => {
                setSelectedObject(null);
            };


            // 업로드된 이미지가 테두리 안을 가득 채웠는지 확인하는 함수
            const checkIfFilled = () => {
                console.log("checkIfFilled");
                const canvas = fabricRef.current;
                const checkAreaInside = checkAreaInsideRef.current;
                const checkAreaOutside = checkAreaOutsideRef.current;
                const objects = canvas.getObjects().filter((obj) => obj.type === "image" && obj.name !== "Phone Base Image");

                // setAlertMessage("");
                let msg = "";
                let firstCheckOk = false;

                if (checkAreaInside) {
                    const checkAreaInsideRect = checkAreaInside.getBoundingRect();

                    for (const obj of objects) {
                        if (obj === checkAreaInsideRect) return;

                        const objBounds = obj.getBoundingRect();
                        const boundaryLeft = checkAreaInsideRect.left;
                        const boundaryTop = checkAreaInsideRect.top;
                        const boundaryRight = boundaryLeft + checkAreaInsideRect.width;
                        const boundaryBottom = boundaryTop + checkAreaInsideRect.height;

                        if (
                            objBounds.left < boundaryLeft ||
                            objBounds.top < boundaryTop ||
                            objBounds.left + objBounds.width > boundaryRight ||
                            objBounds.top + objBounds.height > boundaryBottom
                        ) {
                            // alert('테두리 안쪽을 모두 채우지 않았습니다.');
                            console.log("이미지가 목업을 벗어남");
                            msg += "이미지가 목업을 벗어남 ";
                            firstCheckOk = true;
                            break;
                        }
                    }
                }

                if (checkAreaOutside) {
                    const checkAreaOutsideRect = checkAreaOutside.getBoundingRect();

                    for (const obj of objects) {
                        if (obj === checkAreaOutsideRect) return;

                        const objBounds = obj.getBoundingRect();

                        const checkSideLeft = checkAreaOutsideRect.left;
                        const checkSideTop = checkAreaOutsideRect.top;
                        const checkSideRight = checkSideLeft + checkAreaOutsideRect.width;
                        const checkSideBottom = checkSideTop + checkAreaOutsideRect.height;

                        // 테두리 영역을 완전히 덮는지 확인
                        const isCovering =
                            objBounds.left <= checkSideLeft &&
                            objBounds.top <= checkSideTop &&
                            objBounds.left + objBounds.width >= checkSideRight &&
                            objBounds.top + objBounds.height >= checkSideBottom;

                        if (firstCheckOk && !isCovering) {
                            console.log("테두리 영역을 가득 채우지 않음");
                            msg = ` 테두리 영역을 가득 채우지 않음 ==> ${obj.name}`;
                            break;
                        } else {
                            msg = "";
                        }
                    }
                }

                // setAlertMessage(msg);
            };

            const initialPosition = () => {
                const activeObject = canvas.getActiveObject();
                
                if (!activeObject) return;

                initialPositionRef.current = {
                    x: activeObject.left,
                    y: activeObject.top,
                };
            }

            // 바깥영역 클릭시 객체 선택 해제
            const handleOutsideClicked = (opt) => {
                const activeObject = canvas.getActiveObject();
                if (activeObject && activeObject.type === "i-text") {
                    return;
                }

                if (!phoneBaseImageRef || !phoneBaseImageRef.current) {
                    return;
                }

                const phoneBaseImage = phoneBaseImageRef.current;

                // 객체의 실제 화면상 좌표와 크기 계산
                const clipRect = {
                    left: phoneBaseImage.left || 0,
                    top: phoneBaseImage.top || 0,
                    width: phoneBaseImage.getScaledWidth() || phoneBaseImage.width || 0,
                    height: phoneBaseImage.getScaledHeight() || phoneBaseImage.height || 0,
                };

                // 객체의 회전이 있는 경우를 고려
                if (phoneBaseImage.angle) {
                    const rad = fabric.util.degreesToRadians(phoneBaseImage.angle);
                    const sin = Math.sin(rad);
                    const cos = Math.cos(rad);
                    const w = clipRect.width;
                    const h = clipRect.height;

                    clipRect.width = Math.abs(w * cos) + Math.abs(h * sin);
                    clipRect.height = Math.abs(w * sin) + Math.abs(h * cos);
                }

                const pointer = canvas.getPointer(opt.e);

                const isInClippingArea =
                    pointer.x >= clipRect.left &&
                    pointer.y >= clipRect.top &&
                    pointer.x <= clipRect.left + clipRect.width &&
                    pointer.y <= clipRect.top + clipRect.height;

                if (isInClippingArea) {
                    return;
                } else {
                    canvas.discardActiveObject();
                    canvas.requestRenderAll();
                    setSelectedObject(null);
                }
            };



            // 객체 선택 이벤트 핸들러 등록
            canvas.on("selection:created", handleObjectSelected);
            canvas.on("selection:updated", handleObjectSelected);
            canvas.on("selection:cleared", handleSelectionCleared);

            // 업로드된 이미지가 테두리 안을 가득 채웠는지 확인
            canvas.on("object:modified", checkIfFilled);

            // 바깥영역 클릭시 객체 선택 해제
            canvas.on("mouse:down", handleOutsideClicked);

            // 히스토리 관련 이벤트 핸들러 등록
            canvas.on("object:added", historyControlsRef.current.handleCanvasChange);
            canvas.on("object:modified", historyControlsRef.current.handleCanvasChange);
            canvas.on("object:removed", historyControlsRef.current.handleCanvasChange);


            canvas.on("mouse:down", initialPosition);


            console.log("캔버스 이벤트 초기화 완료");

            resolve();
        });
    };


    // 화면 크기에 맞춰 줌 레벨 조정
    const adjustZoom = () => {
        return new Promise((resolve, reject) => {
            if (!fabricRef.current || !containerRef.current) return;

            const canvas = fabricRef.current;
            const container = containerRef.current;

            // 화면에 보여줄 영역의 폭
            const screenViewWidth = 1000;

            // 컨테이너의 실제 크기 가져오기
            const containerWidth = container.clientWidth;
            const containerHeight = container.clientHeight;
            console.log(`containerWidth=${containerWidth}`);
            console.log(`containerHeight=${containerHeight}`);

            let scale = containerWidth / screenViewWidth;

            // 가로, 세로 중 더 작은 비율을 줌 레벨로 사용
            // const scaleX = containerWidth / CANVAS_DIMENSIONS.ORIGINAL_WIDTH;
            // const scaleY = containerHeight / CANVAS_DIMENSIONS.ORIGINAL_HEIGHT;
            // let scale = Math.min(scaleX, scaleY);
            // scale = Math.min(scale, 1); // 스케일이 1보다 크면 1로 제한 (원본 크기 이상으로 커지지 않도록)
            console.log("scale=" + scale);

            // 실제 표시될 캔버스 크기 계산
            const displayWidth = CANVAS_DIMENSIONS.ORIGINAL_WIDTH * scale;
            const displayHeight = CANVAS_DIMENSIONS.ORIGINAL_HEIGHT * scale;

            canvas.setZoom(scale);

            canvasScaleRef.current = scale;

            // 컨테이너 크기가 아닌 실제 표시될 크기로 설정
            canvas.setWidth(displayWidth);
            canvas.setHeight(displayHeight);
            canvas.requestRenderAll();

            canvasCenterRef.current = canvas.getCenter();


            resolve();
        });
    };

    
       
    usePinchEvents(fabricRef, orderControlsRef);




    const handleDoubleTap = async (target) => {

        if (target && target.type === "i-text" && !target.editable) {
            if (!target.hasControls) {
                return;
            }
            target.editable = true;
            // await resizeCanvas(true);
            if (!target.isEditing) {
                target.enterEditing();
                // target.set({isNewFocus: false}); // 텍스트 추가후 바로 편집 관련
                setIsTextPopupOpen(false);
                if (target.text === TEXT_DEFAULTS.GUIDE_TEXT_KOR || 
                    target.text === TEXT_DEFAULTS.GUIDE_TEXT_ENG) {
                    target.selectAll();
                    // console.log(`target.type=${target.type}, target.isStatic=${target.isStatic}, target.hasControls=${target.hasControls}`);
                }

                // TODO 여기 해제
                // setTimeout(() => {
                //     // 부모창으로 데이터 전송
                //     window.parent.postMessage({
                //         type: 'GET_VIEWPORT_HEIGHT'
                //     }, 'https://akan.co.kr');
                // }, 200);
            }
        }
    };

    useDoubleTapEvents(fabricRef, handleDoubleTap);

    // const zoomContainer = async (isSelection, height) => {
    const zoomContainer = async (isSelection, viewportHeight) => {

        if (!fabricRef.current || !containerRef.current) return;


        if (isSelection) {
            // console.log(`[${canvasId}] resizeCanvas: 축소`, originalSizeRef.current);

            const TOOLBAR_HEIGHT = textToolbarDivRef.current.clientHeight;
            // console.log(`textToolbarDivRef=${textToolbarDivRef.current.clientHeight}, colorControlsDivRef=${colorControlsDivRef.current.clientHeight}`);

            const originalHeight = originalWindowHeight.current;
            const scale = viewportHeight / originalHeight;
            // const scale = (height ? height : window.visualViewport.height) / originalHeight;
            const scaleRate = scale * 120;
            const topOffset = scaleRate - 100 - (TOOLBAR_HEIGHT / originalHeight / 2) * 100;
            console.log(`scale=${scale}, scaleRate=${scaleRate}, topOffset=${topOffset}`);
            setDynamicTransform(scaleRate, scaleRate, topOffset);

            // 컨테이너 크기와 위치 조정
            containerRef.current.classList.remove('zoom-in');
            containerRef.current.classList.add('dynamic-zoom-out');

            topMenuDivRef.current.classList.remove("show");
            topMenuDivRef.current.classList.add("hide");
            orderControlsDivRef.current.classList.remove("show");
            orderControlsDivRef.current.classList.add("hide");
        } else {
            // console.log(`[${canvasId}] resizeCanvas: 복구`, originalSizeRef.current);

            // 컨테이너 크기 설정
            containerRef.current.classList.remove('dynamic-zoom-out');
            containerRef.current.classList.add('zoom-in');

            fabricRef.current.discardActiveObject();
        }

    };

    const setDynamicTransform = useCallback((scaleX, scaleY, translateY) => {
        const style = document.createElement("style");
        style.textContent = `
            .canvas-container.dynamic-zoom-out {
                transform: scale(${scaleX}%, ${scaleY}%) translateY(${translateY}%);
                transform-origin: center center;
                transition: all 0.5s ease;
            }
        `;
        document.head.appendChild(style);
    }, []);
    

    const moveToolbar = async (isSelection) => {
    // const moveToolbar = async (isSelection, height) => {
        if (!textDialogRef.current || !colorControlsDivRef.current) {
            return;
        }

        const textControlsDivOffset = 0;

        if (isSelection) {
            const windowHeight = window.innerHeight;
            const viewportHeight = window.visualViewport.height;
            // const viewportHeight = height ? height : window.visualViewport.height;

            topMenuDivRef.current.classList.remove("show");
            topMenuDivRef.current.classList.add("hide");
            orderControlsDivRef.current.classList.remove("show");
            orderControlsDivRef.current.classList.add("hide");
            textToolbarDivRef.current.classList.remove("hide");
            textToolbarDivRef.current.classList.add("show");
            textToolbarDivRef.current.style.bottom = `${windowHeight - viewportHeight + textControlsDivOffset}px`;
            // colorControlsDivRef.current.style.bottom = `${windowHeight - viewportHeight + 0}px`;
        } else {
            topMenuDivRef.current.classList.remove("hide");
            topMenuDivRef.current.classList.add("show");
            textToolbarDivRef.current.style.bottom = `${textControlsDivOffset}px`;
            // colorControlsDivRef.current.style.bottom = "0px";
        }
    };

    const handleVirtualKeyboardShow = async () => {
    // const handleVirtualKeyboardShow = async (height) => {
        // alert(isLandscapeMode());

        if (!isLandscapeMode()) {

            const viewportHeight = window.visualViewport.height;
            // const viewportHeight = height ? height : window.visualViewport.height;
            const windowHeight = originalWindowHeight.current;
            const isSelection = windowHeight > viewportHeight;
            // console.log(`handleVirtualKeyboardShow::isSelection=${isSelection}, windowHeight=${windowHeight}, viewportHeight=${viewportHeight}`);
            // alert(`windowHeight=${windowHeight}, viewportHeight=${viewportHeight}`);

            await moveToolbar(isSelection);
            await zoomContainer(isSelection, viewportHeight);
            // await moveToolbar(isSelection, height);
            // await zoomContainer(isSelection, height);
        }
    };


    // 가상키보드 감지 (viewport 높이 변경 감지)
    useEffect(() => {
        window.visualViewport.addEventListener("resize", handleVirtualKeyboardShow);

        window.addEventListener('orientationchange', mobileLandscapeVerification);

        return () => {
            window.visualViewport.removeEventListener("resize", handleVirtualKeyboardShow);
            
            window.removeEventListener('orientationchange', mobileLandscapeVerification);
        };
    }, [handleVirtualKeyboardShow]);

    
    useEffect(() => {
        // 응답 수신
        window.addEventListener(
            "message",
            function (event) {
                // origin 검사
                if (event.origin !== "https://akan.co.kr") return;

                if (event.data.type === "VIEWPORT_HEIGHT_RESULT") {
                    const height = event.data.height;
                    // alert("부모창 뷰포트 높이:" + height);
                    handleVirtualKeyboardShow(height);
                    // 여기서 height 값을 사용하면 됩니다
                }
            },
            false
        );
    }, []);




    const mobileLandscapeVerification = () => {

        if (isLandscapeMode()) {
            landscapeAlertRef.current.style.display = "flex";
            landscapeAlertRef.current.style.zIndex = "99999";
        } else {
            landscapeAlertRef.current.style.display = "none";
            landscapeAlertRef.current.style.zIndex = "-1";
        }
    };

    const isLandscapeMode = () => {
        const orientation = window.screen.orientation.type;
        return orientation === 'landscape-primary' || orientation === 'landscape-secondary';
    };


    useEffect(() => {

        if (!selectedObject) {
            // colorControlsDivRef.current.classList.remove("show");
            // colorControlsDivRef.current.classList.add("hide");
            textToolbarDivRef.current.classList.remove("show");
            textToolbarDivRef.current.classList.add("hide");
            setIsTextPopupOpen(false);
            setIsColorDialogOpen(false);
            return;
        }
        
        if ((selectedObject.type === "i-text" || selectedObject.type === 'textbox')) {
            // if (selectedObject.isEditing) {
            //     orderControlsDivRef.current.classList.remove("show");
            //     orderControlsDivRef.current.classList.add("hide");
            // } else {
            //     orderControlsDivRef.current.classList.remove("hide");
            //     orderControlsDivRef.current.classList.add("show");
            // }
            if (!selectedObject.isNewFocus) {
                setIsTextPopupOpen(true);
            }
        } else if ((selectedObject.name === "BackgroundColor")) {
            topMenuDivRef.current.classList.remove("show");
            topMenuDivRef.current.classList.add("hide");
            orderControlsDivRef.current.classList.remove("show");
            orderControlsDivRef.current.classList.add("hide");
            zoomContainer(true, window.innerHeight - (300));

            // colorControlsDivRef.current.classList.remove("hide");
            // colorControlsDivRef.current.classList.add("show");
        }

        return () => {
            topMenuDivRef.current.classList.remove("hide");
            topMenuDivRef.current.classList.add("show");
            zoomContainer(false);

            // colorControlsDivRef.current.classList.remove("show");
            // colorControlsDivRef.current.classList.add("hide");
            textToolbarDivRef.current.classList.remove("show");
            textToolbarDivRef.current.classList.add("hide");
        }

    }, [selectedObject]);


    
    const updateOverlayGroup = () => {
        const canvas = fabricRef.current;

        // 목업 객체는 항상 최하위를 유지
        const phoneBaseImage = canvas.getObjects().find((obj) => obj.type === "image" && obj.name === "Phone Base Image");
        if (phoneBaseImage) {
            phoneBaseImage.sendBackwards();
        }

        // 음영 오버레이 객체는 항상 최상위를 유지
        const overlayGroup = canvas.getObjects().find((obj) => obj.type === "group" && obj.name === "Overlay Group");
        if (overlayGroup) {
            overlayGroup.bringToFront();
        }

        // 가이드라인
        const vGuideLine = canvas.getObjects().find((obj) => obj.type === "line" && obj.name === "vGuideLine");
        if (vGuideLine) {
            vGuideLine.set({opacity: 0});
            vGuideLine.bringToFront();
        }
    };


    /**
     * 텍스트 추가
     */
    const addText = (sampleText = '텍스트 입력', fontFamily) => {
        const canvas = fabricRef.current;
        const text = new fabric.IText(sampleText, {
            width: 400,
            left: CANVAS_DIMENSIONS.CENTER_X,
            top: CANVAS_DIMENSIONS.CENTER_Y,
            originX: "center",
            originY: "center",
            fontSize: 80,
            textAlign: "center",
            fill: "#000000",
            lockScalingFlip: true,
            editable: false,
            // isNewFocus: true  // 텍스트 추가후 바로 편집 관련
        });
        canvas.add(text);
        canvas.setActiveObject(text);

        console.log(`fontFamily=`,fontFamily);
        console.log(`textDialogRef.current=`,textDialogRef.current);
        if (textDialogRef.current && fontFamily) {
            textDialogRef.current.setSelectedFont(fontFamily);
        }

        // 텍스트 편집모드 진입
        text.on("editing:entered", function () {
            setTimeout(() => {
                text.lockScalingX = true;
                text.lockScalingY = true;
            }, 100);
        });

        // 텍스트 편집모드 종료
        text.on("editing:exited", function () {
            setTimeout(() => {
                // console.log("텍스트 편집 모드 종료");
                // zoomContainer(false);
                text.editable = false;
                text.lockScalingX = false;
                text.lockScalingY = false;
                // orderControlsRef.current.unlockObject();

                // TODO 여기 해제
                // setTimeout(() => {
                //     // 부모창으로 데이터 전송
                //     window.parent.postMessage({
                //         type: 'GET_VIEWPORT_HEIGHT'
                //     }, 'https://akan.co.kr');
                // }, 200);
            }, 100);
        });

        
        // 텍스트 추가 후 바로 편집모드
        setTimeout( async () => {
            // await handleDoubleTap(text); // 텍스트 추가후 바로 편집 관련
            canvas.setActiveObject(text);
        }, 200);
        
    };

    /**
     * 사각형 추가
     */
    const addRectangle = () => {
        const canvas = fabricRef.current;
        const randomColor = COLOR_PALETTE[Math.floor(Math.random() * COLOR_PALETTE.length)];
        const rect = new fabric.Rect({
            width: 100,
            height: 100,
            left: CANVAS_DIMENSIONS.CENTER_X,
            top: CANVAS_DIMENSIONS.CENTER_Y,
            originX: "center",
            originY: "center",
            fill: randomColor,
            lockScalingFlip: true,
        });
        canvas.add(rect);
        canvas.setActiveObject(rect);
    };

    // 캔버스에 이미지 추가
    const addImage = (imgUrl, isSticker = false) => {
        console.log("imgUrl=", imgUrl);

        const canvas = fabricRef.current;

        fabric.Image.fromURL(
            imgUrl,
            (img) => {
                img.set({
                    name: "사진",
                    left: CANVAS_DIMENSIONS.CENTER_X,
                    top: CANVAS_DIMENSIONS.CENTER_Y,
                    originX: "center",
                    originY: "center",
                    opacity: 1,
                });

                // 큰 이미지는 목업에 맞춰 줄여준다.
                if (isSticker) {
                    img.scaleToWidth(img.width / 2);
                }
                else {
                    if (img.width > phoneBaseImageRef.current.width) {
                        img.scaleToWidth(phoneBaseImageRef.current.width);
                    } else if (img.height > phoneBaseImageRef.current.height) {
                        img.scaleToHeight(phoneBaseImageRef.current.height);
                    }
                }

                canvas.add(img);
                canvas.setActiveObject(img);
                canvas.renderAll();
            },
            { crossOrigin: "Anonymous" }
        );
    };

    // 선택된 디자인 이미지 추가 (통이미지)
    const loadDesignRaster = async () => {
        console.log("선택된 디자인 이미지 추가");

        const canvas = fabricRef.current;

        const designImg = await loadImage(IMG_SERVER_DOMAIN + `/static-img/design/${designType}/${designCode}_1.webp`);

        designImg.set({
            name: "디자인",
            left: CANVAS_DIMENSIONS.CENTER_X,
            top: CANVAS_DIMENSIONS.CENTER_Y,
            originX: "center",
            originY: "center",
            opacity: 1,
        });

        // 큰 이미지는 목업에 맞춰 줄여준다.
        // if (img.width > phoneBaseImageRef.current.width) {
        //     img.scaleToWidth(phoneBaseImageRef.current.width);
        // } else if (img.height > phoneBaseImageRef.current.height) {
        //     img.scaleToHeight(phoneBaseImageRef.current.height);
        // }

        canvas.add(designImg);
        canvas.setActiveObject(designImg);
        canvas.renderAll();
    };

    // 선택된 디자인 스티커 추가 (JSON 객체)
    const loadDesignObject = async () => {
        console.log("선택된 디자인 스티커 추가");

        const canvas = fabricRef.current;

        const json = {
            objects: [
                {
                    type: "rect",
                    version: "5.2.4",
                    originX: "center",
                    originY: "center",
                    left: 554.7064296571011,
                    top: 665.9097143181374,
                    width: 100,
                    height: 100,
                    fill: "#2f823f",
                    stroke: null,
                    strokeWidth: 1,
                    strokeDashArray: null,
                    strokeLineCap: "butt",
                    strokeDashOffset: 0,
                    strokeLineJoin: "miter",
                    strokeUniform: false,
                    strokeMiterLimit: 4,
                    scaleX: 14.106583224869938,
                    scaleY: 14.106583224869938,
                    angle: 0,
                    flipX: false,
                    flipY: false,
                    opacity: 1,
                    shadow: null,
                    visible: true,
                    backgroundColor: "",
                    fillRule: "nonzero",
                    paintFirst: "fill",
                    globalCompositeOperation: "source-over",
                    skewX: 0,
                    skewY: 0,
                    rx: 0,
                    ry: 0,
                    selectable: true,
                    evented: true,
                    hasControls: true,
                    padding: 0,
                },
                {
                    type: "image",
                    version: "5.2.4",
                    originX: "center",
                    originY: "center",
                    left: CANVAS_DIMENSIONS.CENTER_X,
                    top: 833.6431226765799,
                    width: 216,
                    height: 211,
                    fill: "rgb(0,0,0)",
                    stroke: null,
                    strokeWidth: 0,
                    strokeDashArray: null,
                    strokeLineCap: "butt",
                    strokeDashOffset: 0,
                    strokeLineJoin: "miter",
                    strokeUniform: false,
                    strokeMiterLimit: 4,
                    scaleX: 1,
                    scaleY: 1,
                    angle: 0,
                    flipX: false,
                    flipY: false,
                    opacity: 1,
                    shadow: null,
                    visible: true,
                    backgroundColor: "",
                    fillRule: "nonzero",
                    paintFirst: "fill",
                    globalCompositeOperation: "source-over",
                    skewX: 0,
                    skewY: 0,
                    cropX: 0,
                    cropY: 0,
                    selectable: true,
                    evented: true,
                    hasControls: true,
                    crossOrigin: "anonymous",
                    padding: 0,
                    name: "사진",
                    src: "http://183.99.52.66:3001/customer-img/CAFE24/TEMP_m42dyq60563/d6c313ce-d132-4739-a9ae-9f0d6133830c.webp",
                    filters: [],
                },
                {
                    type: "text",
                    version: "5.2.4",
                    originX: "center",
                    originY: "center",
                    left: CANVAS_DIMENSIONS.CENTER_X,
                    top: 1001.1771995043371,
                    width: 233.10001373291013,
                    height: 50.849999999999994,
                    fill: "#000000",
                    stroke: null,
                    strokeWidth: 1,
                    strokeDashArray: null,
                    strokeLineCap: "butt",
                    strokeDashOffset: 0,
                    strokeLineJoin: "miter",
                    strokeUniform: false,
                    strokeMiterLimit: 4,
                    scaleX: 1,
                    scaleY: 1,
                    angle: 0,
                    flipX: false,
                    flipY: false,
                    opacity: 1,
                    shadow: null,
                    visible: true,
                    backgroundColor: "",
                    fillRule: "nonzero",
                    paintFirst: "fill",
                    globalCompositeOperation: "source-over",
                    skewX: 0,
                    skewY: 0,
                    fontFamily: "Tenada",
                    fontWeight: "normal",
                    fontSize: 45,
                    text: "DINOSOUR",
                    underline: false,
                    overline: false,
                    linethrough: false,
                    textAlign: "center",
                    fontStyle: "normal",
                    lineHeight: 1,
                    textBackgroundColor: "",
                    charSpacing: 0,
                    styles: [],
                    direction: "ltr",
                    path: null,
                    pathStartOffset: 0,
                    pathSide: "left",
                    pathAlign: "baseline",
                    selectable: true,
                    evented: true,
                    hasControls: true,
                    padding: 0,
                },
            ],
        };

        // 폰트를 먼저 다운로드
        const fontFamilies = json.objects.filter((obj) => obj.fontFamily).map((obj) => obj.fontFamily);

        for (const font of fontFamilies) {
            await textDialogRef.current.fontDownload(font);
        }

        // 폰트 다운로드 후 객체 추가
        fabric.util.enlivenObjects(json.objects, function (objects) {
            objects.forEach((obj) => {
                canvas.add(obj);
            });
            canvas.renderAll();
            updateOverlayGroup();
        });
    };

    return (
        <>
            <div ref={topMenuDivRef} className="top-menu-container">
                <button onClick={saveContentsToServer} className="tmp-btn red">
                    완료
                </button>
                <HistoryControls
                    ref={historyControlsRef}
                    fabricRef={fabricRef}
                    selectedObject={selectedObject}
                    // setHisIndex={setHisIndex}
                    updateOverlayGroup={updateOverlayGroup}
                    initialPositionRef={initialPositionRef}
                />
                <button
                    onClick={() => {
                        // openPreview();
                        setIsPreviewOpen(true);
                    }}
                    className="tmp-btn red"
                >
                    미리보기
                </button>
                {/* <button onClick={loadContents} className="tmp-btn">임시</button> */}
            </div>

            <div ref={appContainerRef} className="app-container">
                <div ref={containerRef} className="canvas-container">
                    <canvas ref={canvasRef} id={canvasId} />
                </div>
            </div>

            <div className={`bottom-menu-container ${selectedObject && selectedObject.type === 'i-text' ? "hide" : "show"}`}>
                <div className="bottom-menu-wrapper">
                    <div className="bottom-menu-item">
                        <button className="menu-icon" onClick={() => setIsImageManagerOpen(true)}>
                            {/* <img src="/tmp/svg/image-photo-svgrepo-com.svg" style={{width: "30px", height: "30px"}}/> */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="#5f6368"
                            >
                                <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm40-80h480L570-480 450-320l-90-120-120 160Zm-40 80v-560 560Z" />
                            </svg>
                        </button>
                        <div>이미지</div>
                    </div>
                    <div className="bottom-menu-item">
                        <button className="menu-icon" onClick={() => setIsTemplatePopupOpen(true)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="#5f6368"
                            >
                                <path d="M420-160v-520H200v-120h560v120H540v520H420Z" />
                            </svg>
                        </button>
                        <div>텍스트</div>
                    </div>
                    <div className="bottom-menu-item">
                        <button className="menu-icon" onClick={() => setIsStickerPopupOpen(true)}>
                            <img src="/tmp/svg/sticker-smile-circle-2-svgrepo-com.svg" style={{width: "30px", height: "30px"}}/>
                        
                            {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="#5f6368"
                            >
                                <path d="M480-480Zm0 400q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q43 0 83 8.5t77 24.5v90q-35-20-75.5-31.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-32-6.5-62T776-600h86q9 29 13.5 58.5T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm320-600v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80ZM620-520q25 0 42.5-17.5T680-580q0-25-17.5-42.5T620-640q-25 0-42.5 17.5T560-580q0 25 17.5 42.5T620-520Zm-280 0q25 0 42.5-17.5T400-580q0-25-17.5-42.5T340-640q-25 0-42.5 17.5T280-580q0 25 17.5 42.5T340-520Zm140 260q68 0 123.5-38.5T684-400H276q25 63 80.5 101.5T480-260Z" />
                            </svg> */}
                        </button>
                        <div>스티커</div>
                    </div>
                    <div className="bottom-menu-item">
                        <button className="menu-icon" onClick={() => setIsColorDialogOpen(true)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="#5f6368"
                            >
                                <path d="M346-140 100-386q-10-10-15-22t-5-25q0-13 5-25t15-22l230-229-106-106 62-65 400 400q10 10 14.5 22t4.5 25q0 13-4.5 25T686-386L440-140q-10 10-22 15t-25 5q-13 0-25-5t-22-15Zm47-506L179-432h428L393-646Zm399 526q-36 0-61-25.5T706-208q0-27 13.5-51t30.5-47l42-54 44 54q16 23 30 47t14 51q0 37-26 62.5T792-120Z" />
                            </svg>
                        </button>
                        <div>배경색</div>
                    </div>
                    {/* <div className="bottom-menu-item">
                        <button className="menu-icon" onClick={() => setIsPreviewOpen(true)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M160-40v-80h640v80H160Zm0-800v-80h640v80H160Zm320 400q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm70-80q45-56 109-88t141-32q77 0 141 32t109 88h70v-480H160v480h70Zm118 0h264q-29-20-62.5-30T480-280q-36 0-69.5 10T348-240Zm132-280q-17 0-28.5-11.5T440-560q0-17 11.5-28.5T480-600q17 0 28.5 11.5T520-560q0 17-11.5 28.5T480-520Zm0 40Z"/></svg></button>
                        <div>미리보기</div>
                    </div> */}

                    {/* <div className="bottom-menu-item">
                        <button className="menu-icon" onClick={loadDesignRaster}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M360-400h400L622-580l-92 120-62-80-108 140Zm-40 160q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z"/></svg></button>
                        <div>디자인로드</div>
                    </div>
                    <div className="bottom-menu-item">
                        <button className="menu-icon" onClick={loadDesignObject}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M560-160v-80h120q17 0 28.5-11.5T720-280v-80q0-38 22-69t58-44v-14q-36-13-58-44t-22-69v-80q0-17-11.5-28.5T680-720H560v-80h120q50 0 85 35t35 85v80q0 17 11.5 28.5T840-560h40v160h-40q-17 0-28.5 11.5T800-360v80q0 50-35 85t-85 35H560Zm-280 0q-50 0-85-35t-35-85v-80q0-17-11.5-28.5T120-400H80v-160h40q17 0 28.5-11.5T160-600v-80q0-50 35-85t85-35h120v80H280q-17 0-28.5 11.5T240-680v80q0 38-22 69t-58 44v14q36 13 58 44t22 69v80q0 17 11.5 28.5T280-240h120v80H280Z"/></svg></button>
                        <div>객체로드</div>
                    </div> */}
                </div>
            </div>

            <div ref={orderControlsDivRef} className={`order-controls ${selectedObject ? "show" : "hide"}`}>
                <OrderControls
                    ref={orderControlsRef}
                    selectedObject={selectedObject}
                    canvas={fabricRef.current}
                    historyControlsRef={historyControlsRef}
                    canvasScale={canvasScaleRef}
                />
            </div>

            <div>
                <div>{alertMessage}</div>

                <StickerDialog
                    isOpen={isStickerPopupOpen}
                    onClose={() => setIsStickerPopupOpen(false)}
                    addImage={addImage}
                />

                <TextDialog
                    ref={textDialogRef}
                    isOpen={isTextPopupOpen}
                    onClose={() => setIsTextPopupOpen(false)}
                    canvas={fabricRef.current}
                    selectedObject={selectedObject}
                    zoomContainer={zoomContainer}
                    handleDoubleTap={handleDoubleTap}
                    topMenuDivRef={topMenuDivRef}
                    orderControlsDivRef={orderControlsDivRef}
                    historyControlsRef={historyControlsRef}
                />

                <ColorControls
                    isOpen={isColorDialogOpen}
                    onClose={() => setIsColorDialogOpen(false)}
                    updateOverlayGroup={updateOverlayGroup}
                    // addBgImage={addBgImage}
                    selectedObject={selectedObject}
                    canvas={fabricRef.current}
                    historyControlsRef={historyControlsRef}
                />

                <TemplateDialog
                    isOpen={isTemplatePopupOpen}
                    onClose={() => setIsTemplatePopupOpen(false)}
                    canvas={fabricRef.current}
                    templateType={"text"}
                    addText={addText}
                />

                <PreviewDialog
                    isOpen={isPreviewOpen}
                    onClose={() => setIsPreviewOpen(false)}
                    canvas={fabricRef.current}
                    designType={designType}
                />

                <ImageManagerDialog
                    isOpen={isImageManagerOpen}
                    onClose={() => setIsImageManagerOpen(false)}
                    addImage={addImage}
                    marketId={marketId}
                    userId={userId}
                />

                <SizeInfoTooltip
                    canvas={fabricRef.current}
                    selectedObject={selectedObject}
                />
            </div>

            <div ref={textToolbarDivRef} className="text-toolbar">
                {/* <TextToolbar
                    selectedObject={selectedObject}
                    canvas={fabricRef.current}
                    // activeFont={activeFont}
                    historyControlsRef={historyControlsRef}
                /> */}
            </div>

            <div className="layer-controls">
                <LayerPanel
                    canvas={fabricRef.current}
                    selectedObject={selectedObject}
                    setSelectedObject={setSelectedObject}
                />
            </div>


            <div id="landscapeAlert" ref={landscapeAlertRef} style={{display: "none"}}>
                세로모드에서만 편집이 가능합니다.<br/>
                화면을 세로로 돌린후 편집해주세요.
            </div>

        </>
    );
});

export default CanvasComponent;
