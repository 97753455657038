import React, { useState, useRef, useEffect } from "react";
// import StickerSection from "./StickerSection";
import { motion, AnimatePresence } from "framer-motion";
import "../style/StickerDialog.css";

const StickerDialog = ({ isOpen, onClose, addImage }) => {

    const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN;
    const IMG_SERVER_DOMAIN = process.env.REACT_APP_IMG_SERVER_DOMAIN;
    const STICKER_IMG_URI = IMG_SERVER_DOMAIN + "/static-img/sticker/";

    const [categories, setCategories] = useState([]);
    const [stickers, setStickers] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [popupTitle, setPopupTitle] = useState("");
    

    // 로드된 스티커 목록을 저장. 재사용.
    const stickersRef = useRef({});

    // 다이얼로그가 열리고 닫힐 때 상태 초기화
    useEffect(() => {
        if (isOpen) {
            fetchCategories();
        } else {
            setSelectedCategory(null);
            setStickers([]);
        }
        
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [isOpen]);

    // 카테고리가 선택되었을 때 스티커 목록 조회
    useEffect(() => {
        if (selectedCategory) {
            // 캐시된 데이터가 있는지 확인
            if (stickersRef.current && stickersRef.current[selectedCategory]) {
                setStickers(stickersRef.current[selectedCategory]);
            } else {
                fetchStickers(selectedCategory);
            }
        }
        return () => {
            setStickers([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [selectedCategory]);


    const fetchCategories = async () => {
        if (categories && categories.length) {
            return;
        }

        try {
            const response = await fetch(SERVER_DOMAIN + "/api/sticker/category-list");
            const data = await response.json();
            if (data.resCd === "SUCCESS") {
                setCategories(data.resObj.stickerCategories);
            }
        } catch (error) {
            console.error("카테고리 목록 조회 실패:", error);
        }
    };

    const fetchStickers = async (categoryCode) => {

        setIsLoading(true);
        try {
            const response = await fetch(SERVER_DOMAIN + "/api/sticker/list?categoryCode=" + categoryCode);
            const data = await response.json();
            if (data.resCd === "SUCCESS") {
                setStickers(data.resObj.stickers);
                stickersRef.current[categoryCode] = data.resObj.stickers;
            }
        } catch (error) {
            console.error("스티커 목록 조회 실패:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category.categoryCode);
        setPopupTitle(category.categoryName);
    };

    const handleBack = () => {
        setSelectedCategory(null);
        setStickers([]);
        setPopupTitle("");
    };

    return (
        <div className="sticker-container">
            <AnimatePresence>
                {isOpen && (
                    <>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="sticker-overlay"
                            onClick={() => onClose()}
                        />

                        <motion.div
                            drag="y"
                            dragSnapToOrigin
                            dragConstraints={{ top: 0 }}
                            dragElastic={0.01}
                            onDragEnd={(_, info) => {
                                if (info.offset.y > 100) onClose();
                            }}
                            initial={{ y: "100%" }}
                            animate={{ y: 0 }}
                            exit={{ y: "100%" }}
                            transition={{ type: "spring", damping: 30, stiffness: 200 }}
                            className="sticker-modal"
                        >
                            <div className="drag-handle" />
                            {!selectedCategory ? (
                                <>
                                    <div className="sticker-modal-header">
                                        <div className="toast-title">
                                            <img src="/tmp/svg/cat-halloween-kitty-svgrepo-com.svg" style={{width:"30px", height:"30px"}}/>
                                            <span>스티커 이미지</span>
                                            <div style={{ position: "absolute", right: 0 }} onClick={onClose}>
                                                <img src="/tmp/svg/actions-close-svgrepo-com.svg" style={{ height: "24px", width: "24px" }} />
                                            </div>
                                        </div>

                                        <div className="tag-scroll-container">
                                            <div className="tag-container">
                                                {categories.map((category) => (
                                                    <button
                                                        key={category.categoryCode} // key prop 추가
                                                        className="tag-button"
                                                        onClick={() => handleCategoryClick(category)}
                                                    >
                                                        {category.categoryName}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sticker-modal-content">
                                        {/* 카테고리 목록 */}
                                        {categories.map((category) => (
                                            <section key={category.categoryCode} className="sticker-section">
                                                <div className="header">
                                                    <span onClick={() => handleCategoryClick(category)}>{category.categoryName}</span>
                                                    <span onClick={() => handleCategoryClick(category)}>모두보기</span>
                                                </div>
                                                <div className="content">
                                                    <div className="sticker-image-list">
                                                        {category.stickerImages.map((sticker, index) => (
                                                            <img
                                                                key={index}
                                                                src={STICKER_IMG_URI + sticker.stickerImageFilename}
                                                                alt={sticker.stickerName}
                                                                onClick={() => {
                                                                    addImage(STICKER_IMG_URI + sticker.stickerImageFilename, true);
                                                                    onClose();
                                                                }}
                                                            />
                                                        ))}
                                                        <div className="more-button">
                                                            <button type="button" onClick={() => handleCategoryClick(category)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                    <path
                                                                        d="M17.1 13.004H5.504a.75.75 0 0 1 0-1.5H17.1l-4.377-4.377a.75.75 0 0 1 1.061-1.06l4.95 4.95a1.75 1.75 0 0 1 0 2.474l-4.95 4.95a.75.75 0 1 1-1.06-1.06l4.376-4.377z"
                                                                        fill="currentColor"
                                                                    ></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="sticker-modal-header">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <button
                                                className="back-button"
                                                onClick={handleBack}
                                                style={{
                                                    border: "none",
                                                    background: "none",
                                                    cursor: "pointer",
                                                    fontSize: "16px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "#666",
                                                }}
                                            >
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    <path d="M19 12H5M12 19l-7-7 7-7" />
                                                </svg>
                                            </button>
                                            <span className="sticker-modal-title">{popupTitle}</span>
                                        </div>
                                    </div>
                                    <div className="sticker-modal-content">
                                        {/* 세부 스티커 목록 */}
                                        <div className="sticker-grid">
                                            {isLoading ? (
                                                <div style={{ gridColumn: "1 / -1", textAlign: "center", padding: "20px" }}>로딩 중...</div>
                                            ) : (
                                                stickers.map((sticker) => (
                                                    <div
                                                        key={sticker.no} // key prop 추가
                                                        className="sticker-item"
                                                        onClick={() => {
                                                            addImage(STICKER_IMG_URI + sticker.stickerImageFilename, true);
                                                            onClose();
                                                        }}
                                                    >
                                                        <img
                                                            src={STICKER_IMG_URI + sticker.stickerImageFilename}
                                                            alt={sticker.stickerName}
                                                            className="sticker-image"
                                                        />
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </div>
    );

};

export default StickerDialog;
