export const MARKET_ID = {
    "183.99.52.66": "CAFE24",
    "localhost": "CAFE24",
    "svc.akan.co.kr": "CAFE24",
    // "akan.kr": "WHOLESALE"
};

// 캔버스 크기 관련 상수
export const CANVAS_DIMENSIONS = {
    ORIGINAL_WIDTH: 1600,
    ORIGINAL_HEIGHT: 1600,
    get CENTER_X() {
        return this.ORIGINAL_WIDTH / 2;
    },
    get CENTER_Y() {
        return this.ORIGINAL_HEIGHT / 2;
    },
    PADDING: 200,
};

// 스냅 임계값 설정
export const SNAP_THRESHOLD = 10;

// Fabric.js 객체 속성 관련 상수
export const FABRIC_JSON_PROPERTIES = [
    "selectable",
    "hasControls",
    "evented",
    'lockMovementX',
    'lockMovementY',
    'lockRotationX',
    'lockRotationY',
    'lockScalingX',
    'lockScalingY',
    // 'lockSkewingX',
    // 'lockSkewingY',
    'lockUniScaling',
    "crossOrigin",
    "opacity",
    "originX",
    "originY",
    "padding",
    "scaleX",
    "scaleY",
    "top",
    "left",
    "width",
    "height",
    "fill",
    "fillRule",
    "stroke",
    "strokeWidth",
    "strokeDashArray",
    "group",
    "name",
    "isStatic",
];

// 컨트롤 설정
export const CONTROL_VISIBILITY = {
    ml: false,
    mb: false,
    mr: false,
    mt: false,
};



// 텍스트 관련 기본값
export const TEXT_DEFAULTS = {
    COLOR: "#000000",
    FONT_SIZE: 80,
    LINE_HEIGHT: 1.0,
    CHAR_SPACING: 0,
    GUIDE_TEXT_KOR: "텍스트 입력",
    GUIDE_TEXT_ENG: "text",
};

export const CONTROL_VISIBILITY_TEXTBOX = { mb: false, mt: false };// 텍스트 박스 컨트롤 설정

// 컬러 팔레트
// export const COLOR_PALETTE = [
//     // 무채색 계열
//     "#FFFFFF", // 순수 흰색
//     "#C0C0C0", // 은색
//     "#808080", // 회색
//     "#36454F", // 차콜 그레이
//     "#000000", // 순수 검정

//     // 빨간색 계열
//     "#FF2400", // 스칼렛
//     "#DC143C", // 크림슨
//     "#FF0000", // 순수 빨강
//     "#8B0000", // 진한 빨강
//     "#800000", // 마룬

//     // 주황색 계열
//     "#FF6347", // 토마토
//     "#FFA500", // 주황

//     // 노란색 계열
//     "#FFD700", // 골드
//     "#FFFF00", // 순수 노랑
//     "#F0E68C", // 카키
//     "#F5DEB3", // 밀색

//     // 초록색 계열
//     "#32CD32", // 라임그린
//     "#008000", // 초록
//     "#808000", // 올리브
//     "#006400", // 다크 그린
//     "#50C878", // 에메랄드

//     // 청록색 계열
//     "#40E0D0", // 터콰이즈
//     "#008080", // 틸

//     // 파란색 계열
//     "#87CEEB", // 하늘색
//     "#0000FF", // 순수 파랑
//     "#4169E1", // 로얄 블루
//     "#000080", // 네이비

//     // 보라색 계열
//     "#E6E6FA", // 라벤더
//     "#EE82EE", // 바이올렛
//     "#800080", // 퍼플
//     "#4B0082", // 인디고
//     "#301934", // 다크 퍼플

//     // 분홍색 계열
//     "#FFC0CB", // 분홍
//     "#FF69B4", // 핫핑크
//     "#FF1493", // 진한 분홍

//     // 갈색 계열
//     "#A52A2A", // 갈색
// ];

export const COLOR_PALETTE = [
    // Achromatic Colors
    { color: "#FFFFFF", name: "White", korName: "흰색" },
    { color: "#C0C0C0", name: "Silver", korName: "은색" },
    { color: "#808080", name: "Gray", korName: "회색" },
    { color: "#36454F", name: "Charcoal Gray", korName: "차콜 회색" },
    { color: "#000000", name: "Black", korName: "검은색" },
  
    // Red Colors
    { color: "#FF2400", name: "Scarlet", korName: "스칼렛" },
    { color: "#DC143C", name: "Crimson", korName: "크림슨" },
    { color: "#FF0000", name: "Red", korName: "빨간색" },
    { color: "#8B0000", name: "Dark Red", korName: "진한 빨간색" },
    { color: "#800000", name: "Maroon", korName: "마룬" },
  
    // Orange Colors
    { color: "#FF6347", name: "Tomato", korName: "토마토색" },
    { color: "#FFA500", name: "Orange", korName: "주황색" },
  
    // Yellow Colors
    { color: "#FFD700", name: "Gold", korName: "금색" },
    { color: "#FFFF00", name: "Yellow", korName: "노란색" },
    { color: "#F0E68C", name: "Khaki", korName: "카키색" },
    { color: "#F5DEB3", name: "Wheat", korName: "밀색" },
  
    // Green Colors
    { color: "#32CD32", name: "Lime Green", korName: "라임색" },
    { color: "#008000", name: "Green", korName: "녹색" },
    { color: "#808000", name: "Olive", korName: "올리브색" },
    { color: "#006400", name: "Dark Green", korName: "진한 녹색" },
    { color: "#50C878", name: "Emerald", korName: "에메랄드색" },
  
    // Cyan Colors
    { color: "#40E0D0", name: "Turquoise", korName: "터키색" },
    { color: "#008080", name: "Teal", korName: "틸색" },
  
    // Blue Colors
    { color: "#87CEEB", name: "Sky Blue", korName: "하늘색" },
    { color: "#0000FF", name: "Blue", korName: "파란색" },
    { color: "#4169E1", name: "Royal Blue", korName: "로열 블루" },
    { color: "#000080", name: "Navy", korName: "남색" },
  
    // Purple Colors
    { color: "#E6E6FA", name: "Lavender", korName: "라벤더색" },
    { color: "#EE82EE", name: "Violet", korName: "보라색" },
    { color: "#800080", name: "Purple", korName: "자주색" },
    { color: "#4B0082", name: "Indigo", korName: "인디고색" },
    { color: "#301934", name: "Dark Purple", korName: "진한 보라색" },
  
    // Pink Colors
    { color: "#FFC0CB", name: "Pink", korName: "분홍색" },
    { color: "#FF69B4", name: "Hot Pink", korName: "핫 핑크" },
    { color: "#FF1493", name: "Deep Pink", korName: "진한 핑크" },
  
    // Brown Colors
    { color: "#A52A2A", name: "Brown", korName: "갈색" },
  ];

// 파일 업로드 관련 제한
export const FILE_LIMITS = {
    USER_IMAGE_LIMIT_SIZE: 20 * 1024 * 1024, // 20MB
    MAX_DIMENSION: 5000, // 5000px
    ALLOWED_TYPES: ["image/jpeg", "image/png", "image/gif"],
};

// 이벤트 디바운스/쓰로틀 타이밍
export const TIMING = {
    DEBOUNCE_DELAY: 250,
    THROTTLE_DELAY: 100,
};

// API 엔드포인트

export const API_INFO = {
    ENDPOINTS: {
        USER_AUTH_VERIFY: "/api/auth/verify",
        USER_IMAGE_UPLOAD: "/api/user-image/upload",
        EDITED_IMAGE_UPLOAD: "/api/edited-image/upload",
        EDITED_IMAGE_UPLOAD_DATA: "/api/edited-image/upload-data",
        EDITED_IMAGE_LOAD: "/api/edited-image/load/",
    },
    JSON_HEADERS: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
    MULTIPART_HEADERS: {
        "Accept": "application/json",
    }
}


// 에러 메시지
export const ERROR_MESSAGES = {
    FILE_SIZE_OVER_AND_IMAGE_MAX_DIMENSION:
        "파일 크기는 20MB를 초과할 수 없고 해상도는 5000px이하여야 합니다.",
    FILE_SIZE_OVER: "파일 크기는 20MB를 초과할 수 없습니다.",
    IMAGE_MAX_DIMENSION: "5000px 이상의 이미지는 업로드가 불가능합니다.",
    IMAGE_MIN_DIMENSION:
        "500px 미만의 이미지는 품질저하로 업로드가 불가능합니다.",
    INVALID_FILE_TYPE: "지원하지 않는 파일 형식입니다.",
    LOAD_FAILED: "캔버스 로드에 실패했습니다.",
    SAVE_FAILED: "캔버스 저장에 실패했습니다.",
    FONT_LOAD_FAILED: "폰트 로딩에 실패했습니다.",
};

// 상수 객체를 불변으로 만들기
Object.freeze(CANVAS_DIMENSIONS);
Object.freeze(CONTROL_VISIBILITY);
Object.freeze(TEXT_DEFAULTS);
Object.freeze(COLOR_PALETTE);
Object.freeze(FILE_LIMITS);
Object.freeze(TIMING);
Object.freeze(API_INFO);
Object.freeze(ERROR_MESSAGES);
