import { useEffect, useRef } from "react";

const EVENT_CONFIG = {
    MOVE_THRESHOLD: 10, // 터치 이동 임계값
    DOUBLE_TAP_INTERVAL: 500, // 더블탭(더블클릭) 인식 시간 간격
    DOUBLE_TAP_DISTANCE: 10, // 더블탭 시 두 번째 탭의 위치 허용 범위(px)
};

export function useDoubleTapEvents(fabricRef, handleDoubleTap) {
    // 마우스/터치 이벤트 관련 상태 변수
    const mouseDownPosition = useRef({ x: 0, y: 0 });
    const isMouseDown = useRef(false);

    // 탭/더블탭 감지용 변수
    const lastTapTime = useRef(0);
    const lastTapPosition = useRef({ x: 0, y: 0 });

    // 단일 탭 지연 처리용 타이머
    const singleTapTimer = useRef(null);


    const handleSingleTap = (canvas, opt) => {
        console.log("Single Tap Detected!");
        // 싱글탭 처리 로직
    };

    const handleMouseUpEvent = (canvas, opt) => {
        if (!isMouseDown.current) return;
        isMouseDown.current = false;

        const { x, y } = opt.pointer;
        const dx = x - mouseDownPosition.current.x;
        const dy = y - mouseDownPosition.current.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        // 드래그 판단
        if (distance > EVENT_CONFIG.MOVE_THRESHOLD) {
            console.log("Dragged!");
            return;
        }

        // 탭(클릭) 관련 로직
        const currentTime = Date.now();
        const timeDiff = currentTime - lastTapTime.current;
        const dxLast = x - lastTapPosition.current.x;
        const dyLast = y - lastTapPosition.current.y;
        const lastDistance = Math.sqrt(dxLast * dxLast + dyLast * dyLast);

        // 더블탭(더블클릭) 판단
        if (timeDiff < EVENT_CONFIG.DOUBLE_TAP_INTERVAL && lastDistance < EVENT_CONFIG.DOUBLE_TAP_DISTANCE) {
            // 기존 단일 탭 타이머 취소
            if (singleTapTimer.current) {
                clearTimeout(singleTapTimer.current);
                singleTapTimer.current = null;
            }
            const target = canvas.findTarget(opt.e, true);
            handleDoubleTap(target);
        } else {
            // 단일 탭 후보 상태
            if (singleTapTimer.current) {
                clearTimeout(singleTapTimer.current);
            }
            singleTapTimer.current = setTimeout(() => {
                handleSingleTap(canvas, opt);
                singleTapTimer.current = null;
            }, EVENT_CONFIG.DOUBLE_TAP_INTERVAL);
        }

        lastTapTime.current = currentTime;
        lastTapPosition.current = { x, y };
    };

    useEffect(() => {
        const canvas = fabricRef.current;
        if (!canvas) return;

        const handleMouseDown = (opt) => {
            const { x, y } = opt.pointer;
            isMouseDown.current = true;
            mouseDownPosition.current = { x, y };
        };

        const handleMouseUp = (opt) => {
            handleMouseUpEvent(canvas, opt);
        };

        console.log("캔버스 이벤트 초기화");

        // 이벤트 등록
        canvas.on("mouse:down", handleMouseDown);
        canvas.on("mouse:up", handleMouseUp);

        return () => {
            // 이벤트 정리
            canvas.off("mouse:down", handleMouseDown);
            canvas.off("mouse:up", handleMouseUp);
        };
    }, [fabricRef]);
}
