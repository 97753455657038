export const FONT_TYPES = [
    {type: 'PENS', typeName: '손글씨체', comment: '펜촉의 감촉이 느껴지는 캘리그라피', icon: '/tmp/svg/quill-svgrepo-com.svg'},
    {type: 'BOLD', typeName: '굵은체', comment: '강렬하고 임팩트 있는 느낌', icon: '/tmp/svg/bold-type-svgrepo-com.svg'},
    {type: 'CUTE', typeName: '귀여운체', comment: '상큼발랄, 기분 좋아지는 폰트', icon: '/tmp/svg/heart4-svgrepo-com.svg'},
    {type: 'SPEC', typeName: '독특한체', comment: '창의적인 감각, 힙스터 스타일', icon: '/tmp/svg/android-hand-drawn-logo-outline-svgrepo-com.svg'},
    {type: 'ENG', typeName: '영문체', comment: 'Create Your Signature Style', icon: '/tmp/svg/english-svgrepo-com.svg'}
];


export const FONTS = [

    {recommend: 3   , type:"PENS",language:"KOR",name:"신과장체",path:"/assets/fonts/SangSangShin.woff2",fontFamily:"SangSangShin",sampleImage:"/assets/font-preview/SangSangShin",isLoaded:false},
    {recommend: null, type:"PENS",language:"KOR",name:"코트라 손글씨체",path:"/assets/fonts/KOTRA_SONGEULSSI.woff2",fontFamily:"KOTRA_SONGEULSSI",sampleImage:"/assets/font-preview/KOTRA_SONGEULSSI",isLoaded:false},
    {recommend: null, type:"PENS",language:"KOR",name:"그림일기체",path:"/assets/fonts/HakgyoansimGeurimilgiTTF-R.woff2",fontFamily:"HakgyoansimGeurimilgiTTF-R",sampleImage:"/assets/font-preview/HakgyoansimGeurimilgiTTF-R",isLoaded:false},
    {recommend: null, type:"PENS",language:"KOR",name:"윤초록우산체",path:"/assets/fonts/YoonChildfundkoreaDaeHan.woff2",fontFamily:"YoonChildfundkoreaDaeHan ",sampleImage:"/assets/font-preview/YoonChildfundkoreaDaeHan",isLoaded:false},
    {recommend: null, type:"PENS",language:"KOR",name:"윤봉길체",path:"/assets/fonts/YUN-BONG-GIL.woff2",fontFamily:"YUN-BONG-GIL",sampleImage:"/assets/font-preview/YUN-BONG-GIL",isLoaded:false},
    {recommend: null, type:"PENS",language:"KOR",name:"즐거운이야기체",path:"/assets/fonts/OTEnjoystoriesBA.woff2",fontFamily:"OTEnjoystoriesBA",sampleImage:"/assets/font-preview/OTEnjoystoriesBA",isLoaded:false},

    {recommend: 1   , type:"BOLD",language:"KOR",name:"Y클로버체",path:"/assets/fonts/YClover-Bold.woff2",fontFamily:"YClover-Bold",sampleImage:"/assets/font-preview/YClover-Bold",isLoaded:false},
    {recommend: 2   , type:"BOLD",language:"KOR",name:"속초바다 돋움체",path:"/assets/fonts/SokchoBadaDotum.woff2",fontFamily:"SokchoBadaDotum",sampleImage:"/assets/font-preview/SokchoBadaDotum",isLoaded:false},
    {recommend: null, type:"BOLD",language:"KOR",name:"HS여름물빛체",path:"/assets/fonts/HSSummer.woff2",fontFamily:"HSSummer",sampleImage:"/assets/font-preview/HSSummer",isLoaded:false},
    {recommend: null, type:"BOLD",language:"KOR",name:"빙그레 메로나체",path:"/assets/fonts/BinggraeMelona-Bold.woff2",fontFamily:"BinggraeMelona-Bold",sampleImage:"/assets/font-preview/BinggraeMelona-Bold",isLoaded:false},
    {recommend: null, type:"BOLD",language:"KOR",name:"창원단감아삭체",path:"/assets/fonts/CWDangamAsac-Bold.woff2",fontFamily:"CWDangamAsac-Bold",sampleImage:"/assets/font-preview/CWDangamAsac-Bold",isLoaded:false},
    {recommend: null, type:"BOLD",language:"KOR",name:"집토끼 라운드체",path:"/assets/fonts/HSJiptokki-Round.woff2",fontFamily:"HSJiptokki-Round",sampleImage:"/assets/font-preview/HSJiptokki-Round",isLoaded:false},
    
    {recommend: 4   , type:"CUTE",language:"KOR",name:"온글잎 박다현체",path:"/assets/fonts/Ownglyph_ParkDaHyun.woff2",fontFamily:"Ownglyph_ParkDaHyun",sampleImage:"/assets/font-preview/Ownglyph_ParkDaHyun",isLoaded:false},
    {recommend: null, type:"CUTE",language:"KOR",name:"반달이체",path:"/assets/fonts/KNPSBandali-Regular.woff2",fontFamily:"KNPSBandali-Regular",sampleImage:"/assets/font-preview/KNPSBandali-Regular",isLoaded:false},
    {recommend: null, type:"CUTE",language:"KOR",name:"이서윤체",path:"/assets/fonts/LeeSeoyun.woff2",fontFamily:"LeeSeoyun",sampleImage:"/assets/font-preview/LeeSeoyun",isLoaded:false},
    {recommend: null, type:"CUTE",language:"KOR",name:"하이멜로디체",path:"/assets/fonts/HiMelody-Regular.woff2",fontFamily:"HiMelody",sampleImage:"/assets/font-preview/HiMelody",isLoaded:false},
    {recommend: null, type:"CUTE",language:"KOR",name:"온글잎 김콩해",path:"/assets/fonts/Ownglyph_kimkonghae.woff2",fontFamily:"Ownglyph_kimkonghae",sampleImage:"/assets/font-preview/Ownglyph_kimkonghae",isLoaded:false},
    {recommend: null, type:"CUTE",language:"KOR",name:"봄방학체",path:"/assets/fonts/HakgyoansimBombanghakR.woff2",fontFamily:"HakgyoansimBombanghakR",sampleImage:"/assets/font-preview/HakgyoansimBombanghakR",isLoaded:false},

    {recommend: 5, type:"SPEC",language:"KOR",name:"레몬빙수체",path:"/assets/fonts/SF_IceLemon.woff2",fontFamily:"SF_IceLemon",sampleImage:"/assets/font-preview/SF_IceLemon",isLoaded:false},
    {recommend: null, type:"SPEC",language:"KOR",name:"제주돌담체",path:"/assets/fonts/EF_jejudoldam.woff2",fontFamily:"EF_jejudoldam",sampleImage:"/assets/font-preview/EF_jejudoldam",isLoaded:false},
    {recommend: null, type:"SPEC",language:"KOR",name:"꾸불림체",path:"/assets/fonts/BMkkubulimTTF-Regular.woff2",fontFamily:"BMkkubulimTTF-Regular",sampleImage:"/assets/font-preview/BMkkubulimTTF-Regular",isLoaded:false},
    {recommend: null, type:"SPEC",language:"KOR",name:"영도체",path:"/assets/fonts/Yeongdo-Rg.woff2",fontFamily:"Yeongdo-Rg ",sampleImage:"/assets/font-preview/Yeongdo-Rg",isLoaded:false},
    {recommend: null, type:"SPEC",language:"KOR",name:"수박화체",path:"/assets/fonts/116watermelon.woff2",fontFamily:"116watermelon",sampleImage:"/assets/font-preview/116watermelon",isLoaded:false},
    {recommend: null, type:"SPEC",language:"KOR",name:"암각화체",path:"/assets/fonts/HancomUljuBangudae.woff2",fontFamily:"HancomUljuBangudae",sampleImage:"/assets/font-preview/HancomUljuBangudae",isLoaded:false},

    {recommend: null, type:"ENG",language:"ENG",name:"Cafe24 Loving u",path:"/assets/fonts/Cafe24Lovingu.woff2",fontFamily:"Cafe24Lovingu",sampleImage:"/assets/font-preview/Cafe24Lovingu",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"MOVE Sans",path:"/assets/fonts/MoveSans-Bold.woff2",fontFamily:"MoveSans-Bold",sampleImage:"/assets/font-preview/MoveSans-Bold",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"I AM A PLAYER",path:"/assets/fonts/IAMAPLAYER.woff2",fontFamily:"IAMAPLAYER",sampleImage:"/assets/font-preview/IAMAPLAYER",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"Cafe24 Meongi Black",path:"/assets/fonts/Cafe24MeongiBlack.woff2",fontFamily:"Cafe24MeongiBlack",sampleImage:"/assets/font-preview/Cafe24MeongiBlack",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"Cafe24 Decoshadow",path:"/assets/fonts/Cafe24Decoshadow.woff2",fontFamily:"Cafe24Decoshadow",sampleImage:"/assets/font-preview/Cafe24Decoshadow",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"godo rounded",path:"/assets/fonts/godoRoundedR.woff2",fontFamily:"godoRoundedR",sampleImage:"/assets/font-preview/godoRoundedR",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"Carter One",path:"/assets/fonts/CarterOne-Regular.woff2",fontFamily:"CarterOne-Regular",sampleImage:"/assets/font-preview/CarterOne-Regular",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"Shadows Into Light Two",path:"/assets/fonts/ShadowsIntoLightTwo-Regular.woff2",fontFamily:"ShadowsIntoLightTwo-Regular",sampleImage:"/assets/font-preview/ShadowsIntoLightTwo-Regular",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"Love Ya Like A Sister",path:"/assets/fonts/LoveYaLikeASister-Regular.woff2",fontFamily:"LoveYaLikeASister-Regular",sampleImage:"/assets/font-preview/LoveYaLikeASister-Regular",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"Wallpoet",path:"/assets/fonts/Wallpoet-Regular.woff2",fontFamily:"Wallpoet-Regular",sampleImage:"/assets/font-preview/Wallpoet-Regular",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"Tourney",path:"/assets/fonts/Tourney.woff2",fontFamily:"Tourney",sampleImage:"/assets/font-preview/Tourney",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"Moo Lah Lah",path:"/assets/fonts/MooLahLah-Regular.woff2",fontFamily:"MooLahLah-Regular",sampleImage:"/assets/font-preview/MooLahLah-Regular",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"Rubik Marker Hatch",path:"/assets/fonts/RubikMarkerHatch-Regular.woff2",fontFamily:"RubikMarkerHatch-Regular",sampleImage:"/assets/font-preview/RubikMarkerHatch-Regular",isLoaded:false},
    {recommend: null, type:"ENG",language:"ENG",name:"Rammetto One",path:"/assets/fonts/RammettoOne-Regular.woff2",fontFamily:"RammettoOne-Regular",sampleImage:"/assets/font-preview/RammettoOne-Regular",isLoaded:false},

    {recommend: 6   , type:"BASIC",language:"KOR",name:"네이버 나눔체",path:"/assets/fonts/NanumGothic-Regular.woff2",fontFamily:"NanumGothic-Regular",sampleImage:"/assets/font-preview/NanumGothic-Regular",isLoaded:false},

];
