import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { loadImage,  } from '../utils/fabricUtils';
import { CANVAS_DIMENSIONS, COLOR_PALETTE } from '../constants/Constants';
import '../style/ColorDialog.css';

const ColorControls = ({ isOpen, onClose, updateOverlayGroup, selectedObject, canvas, historyControlsRef }) => {

    console.log(`********************************************* ColorControls 렌더링`);

    const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN;
    const IMG_SERVER_DOMAIN = process.env.REACT_APP_IMG_SERVER_DOMAIN;
    const STICKER_IMG_URI = IMG_SERVER_DOMAIN + "/static-img/sticker/";

    const [activeColorTab, setActiveColorTab] = useState("COLOR");

    const [objectColor, setObjectColor] = useState('');
    const [selectedColor, setSelectedColor] = useState(null);

    const stickersRef = useRef([
        "/tmp/bg-image/bg-image-grim02.webp",
        "/tmp/bg-image/7.webp",
        "/tmp/bg-image/3.webp",
        "/tmp/bg-image/4.webp",
        "/tmp/bg-image/5.webp",
        "/tmp/bg-image/6.webp",
        "/tmp/bg-image/8.webp",
        "/tmp/bg-image/9.webp",
        "/tmp/bg-image/10.webp",
        "/tmp/bg-image/11.webp",
        "/tmp/bg-image/12.webp",
        "/tmp/bg-image/2.webp",
    ]);


    // 팝업이 열릴때 컨트롤 요소 표시
    useEffect(() => {
        console.log(`ColorControls::팝업이 열릴때 컨트롤 요소 표시`);

        if (!canvas) {
            return;
        }

        if (isOpen) {
            const bgColor = canvas.getObjects().find((obj) => obj.type === "rect" && obj.name === "BackgroundColor");

            if (bgColor) {
                canvas.setActiveObject(bgColor);
            }
            
        }
               
        return () => {
            
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [isOpen, canvas]);


    const closePopup = () => {
        canvas.discardActiveObject();
        onClose();
    };


    // 팔레트 색상이 변경 -> 객체 색상 업데이트
    useEffect(() => {
        console.log(`ColorControls::useEffect(1) - 객체 색상 업데이트`);

        if (!objectColor) return;

        // if (selectedObject.name === "배경") {
        //     const bgImage = canvas.getObjects().find((obj) => obj.name === "배경");

        //     if (bgImage) {
        //         canvas.remove(bgImage);
        //     }
        // }
               
        if (selectedObject) {
            if (selectedObject.type === 'text' || selectedObject.type === 'i-text' || selectedObject.type === 'rect') {
                selectedObject.set({
                    fill: objectColor.color
                });                

                canvas.renderAll();
                setSelectedColor(objectColor.color);
                historyControlsRef.current.handleCanvasChange(); // 히스토리에 추가
            } else {

                setSelectedColor(null);
            }
        } else {
            setSelectedColor(null);
        }
               
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [objectColor]);



    // 선택된 객체가 변경 -> 팔레트 색상 업데이트
    useEffect(() => {
        console.log(`ColorControls::useEffect(2) - 팔레트 색상 업데이트`);
        if (selectedObject && (selectedObject.type === 'i-text' || selectedObject.type === 'rect')) {
            setSelectedColor(selectedObject.fill || '#000000');
            console.log(`selectedObject.fill=${selectedObject.fill}`);
            setObjectColor(COLOR_PALETTE.find((obj) => obj.color === selectedObject.fill));
        } else {
            setSelectedColor('');
            setObjectColor(null);
        }
    }, [selectedObject]);

    
    
    // 배경색 변경
    const clickBgColor = (color) => {

        const bgColor = canvas.getObjects().find((obj) => obj.type === "rect" && obj.name === "BackgroundColor");

        if (bgColor) {
            // canvas.setActiveObject(bgColor);
            bgColor.set({
                fill: color.color
            });                

            canvas.renderAll();
            setSelectedColor(color.color);
            setObjectColor(color);
        }

        console.log(`bgColor.fill=${bgColor.fill}`);

        if (bgColor && bgColor.fill !== 'transparent') {
            const bgImage = canvas.getObjects().find((obj) => obj.name === "배경");

            if (bgImage) {
                canvas.remove(bgImage);
            }
        }
        canvas.renderAll();
    };
    
    // 캔버스에 배경 이미지 추가
    const addBgImage = async (imgUrl) => {
        console.log("imgUrl=", imgUrl);

        const bgImage = canvas.getObjects().find((obj) => obj.name === "배경");

        if (bgImage) {
            canvas.remove(bgImage);
        }

        const bgColor = canvas.getObjects().find((obj) => obj.type === "rect" && obj.name === "BackgroundColor");

        if (bgColor) {
            bgColor.set({fill: 'transparent'});
        }

        const newBgImage = await loadImage(imgUrl);
        newBgImage.set({
            isStatic: false,
            name: "배경",
            left: CANVAS_DIMENSIONS.CENTER_X,
            top: CANVAS_DIMENSIONS.CENTER_Y,
            originX: "center",
            originY: "center",
            opacity: 1,
            hasControls: false,
            selectable: false,
            evented: false
        });
        newBgImage.scaleToWidth(newBgImage.width);
        canvas.add(newBgImage);
        newBgImage.sendToBack();
        updateOverlayGroup();
        // newBgImage.bringToFront();
                
        // canvas.setActiveObject(img);
        canvas.renderAll();

        
    };


    // if (!selectedObject || (selectedObject.type !== 'text' && selectedObject.type !== 'i-text' && selectedObject.type !== 'rect') || (selectedObject.lockRotationX)) {
    //     return null;
    // }

    return (
        <div className="text-container">
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        drag="y"
                        dragSnapToOrigin
                        dragConstraints={{ top: 0 }}
                        dragElastic={0.01}
                        onDragEnd={(_, info) => {
                            if (info.offset.y > 50) closePopup();
                        }}
                        initial={{ y: "100%" }}
                        animate={{ y: 0 }}
                        exit={{ y: "100%" }}
                        transition={{ type: "spring", damping: 30, stiffness: 200 }}
                        className="text-font-modal"
                    >
                        <div className="drag-handle" />
                        <div className="tab-container">
                            <button onClick={() => setActiveColorTab("COLOR")} className={`tab-button ${activeColorTab === "COLOR" ? "active" : ""}`}>
                                배경색
                            </button>
                            <button onClick={() => setActiveColorTab("IMAGE")} className={`tab-button ${activeColorTab === "IMAGE" ? "active" : ""}`}>
                                배경 이미지
                            </button>
                        </div>
                        {activeColorTab === "COLOR" && (
                            <div className="text-font-modal-content">
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", justifyContent: "space-evenly", height: "35px", marginBottom: "10px" }}>
                                    <div style={{ width: "100px", textAlign: "center"}}>{objectColor ? objectColor.korName : "흰색"}</div>
                                    <div style={{ height: "5px", width: "200px", border: "1px solid #000", borderRadius: "1rem", backgroundColor: objectColor ? objectColor.color : "#FFFFFF" }}></div>
                                </div>
                                <div className="color-grid">
                                    {COLOR_PALETTE.map((color) => (
                                        <div key={color.color} className={`color-button-outline ${objectColor && objectColor.color === color.color ? "active" : ""}`}>
                                            <div
                                                key={color.color}
                                                onMouseDown={(e) => e.preventDefault()}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // 이벤트 버블링 방지
                                                    clickBgColor(color);
                                                    // console.log(`color`, color);
                                                }}
                                                className={`color-button`}
                                                style={{
                                                    backgroundColor: color.color,
                                                    border: "#FFFFFF" === color.color ? "1px solid #ccc" : "",
                                                }}
                                            ></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {activeColorTab === "IMAGE" && (
                            <>
                                <div className="bg-image-modal-content">
                                    <div className="bg-image-grid">
                                        {stickersRef.current.map((img, index) => (
                                            <div
                                                key={index}
                                                className="bg-image-item"
                                                onClick={() => {
                                                    addBgImage(img);
                                                    // onClose();
                                                }}
                                            >
                                                <img
                                                    src={img}
                                                    alt={`스티커 ${index + 1}`}
                                                    className="bg-image-image"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ColorControls;